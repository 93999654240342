import amplitude from 'amplitude-js';

function prodOnly<T extends (...args: any) => any>(func: T) {
  const defaultFunc = (args: any) => null;
  return process.env.NODE_ENV === 'production' ? func : (defaultFunc as T);
}

const initAmplitude = () => {
  amplitude
    .getInstance()
    .init(process.env.AMPLITUDE_API_KEY || 'f1939daa79e96bdec45c11d0be3bf0e1');
};

const setUserId = (userId: string) => {
  amplitude.getInstance().setUserId(userId);
};

const setUserProperties = (properties: any) => {
  amplitude.getInstance().setUserProperties(properties);
};

const logEvent = (eventType: string, eventProperties?: any) => {
  amplitude.getInstance().logEvent(eventType, eventProperties);
};

const logScreenView = (screenName: string, url: string) => {
  // @ts-ignore
  if (window.gtag) {
    // @ts-ignore
    window.gtag('event', 'page_view', {
      page_title: screenName,
      page_location: url,
      send_to: 'G-MHL772SQ6P',
    });
  }
};

export const analytics = {
  initAmplitude: prodOnly(initAmplitude),
  logEvent: prodOnly(logEvent),
  logScreenView: prodOnly(logScreenView),
  setUserId: prodOnly(setUserId),
  setUserProperties: prodOnly(setUserProperties),
};

export default analytics;
