import React from 'react';

import { Button, Flex, Heading, Text } from '@chakra-ui/react';

interface ErrorScreenProps {
  error?: {
    title: string;
    description: string;
  };
  onDismiss?: () => any;
}

const ErrorScreen: React.FC<ErrorScreenProps> = ({ error, onDismiss }) => (
  <Flex
    flexDirection="column"
    w="100vw"
    h="100vh"
    justifyContent="center"
    alignItems="center"
    px="8"
  >
    <Heading as="h5" textAlign="center">
      {error?.title || 'Error'}
    </Heading>
    {error?.description && (
      <Text my="6" maxW="500px" textAlign="center">
        {error.description}
      </Text>
    )}
    {onDismiss && (
      <Button onClick={onDismiss} size="lg" colorScheme="yellow">
        Got it
      </Button>
    )}
  </Flex>
);

export default ErrorScreen;
