import React, { useEffect, useState } from 'react';
import {
  Redirect,
  Route as RouterRoute,
  Router,
  Switch,
  useLocation,
  matchPath,
} from 'react-router-dom';
import { History } from 'history';
import ScrollToTop from 'navigation/ScrollToTop';

import { useDispatch, useSelector } from 'redux/hooks';
import {
  fetchApproaches,
  fetchcategories,
  fetchIdentities,
  fetchQuizzes,
  fetchTasks,
  fetchTraits,
} from 'redux/slices/quiz';
import { hide404 } from 'redux/slices/app';
import { useQuizzesProgress, analytics } from 'utils';

import ErrorScreen from 'screens/global/ErrorScreen';
import Loading from 'components/Loading';

import navRoutes from './Routes';

/**
 * Retrieve the relevant routes based on the current user's progress
 * - no progress : -> show onboarding
 * - onboarding completed : -> show quizzes
 * - quizzes completed but no food ID register :
 *    --> show quizzes + quizzesCompleted routes
 * - food ID registered : -> redirect to foodId
 * @param onboardingCompleted
 * @param quizzesCompleted
 * @param foodId
 * @returns : {routes: Route[] ; redirectPath: string}
 */
const getRoutes = (
  onboardingCompleted: boolean,
  quizzesCompleted: boolean,
  foodId: string | undefined
) => {
  let routes;
  let redirectPath;

  if (foodId) {
    redirectPath = navRoutes.public.foodId.path(foodId);
  } else if (quizzesCompleted) {
    routes = { ...navRoutes.quizzes, ...navRoutes.quizzesCompleted };
    redirectPath = navRoutes.quizzes.myTests.path();
  } else if (onboardingCompleted) {
    routes = navRoutes.quizzes;
    redirectPath = navRoutes.quizzes.myTests.path();
  } else {
    routes = navRoutes.onboarding;
    redirectPath = navRoutes.onboarding.home.path();
  }

  return { routes, redirectPath };
};

interface RoutesRendererProps {}

// Create Custom Sentry Route component to parameterized transactions
// const Route = Sentry.withSentryRouting(RouterRoute);
const Route = RouterRoute;

const RoutesRenderer: React.FC<RoutesRendererProps> = () => {
  // Load initial data
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const onboardingCompleted = useSelector((state) =>
    Boolean(state.app.onboardingCompleted)
  );

  const sidekickId = useSelector((state) => state.user?.sidekickId);
  const appState = useSelector((state) => state.app);
  const { foodId, show404 } = appState;

  const quizzesProgress = useQuizzesProgress(isLoading);

  const quizzesCompleted =
    appState.quizzesCompleted || quizzesProgress.quizzesCompleted;

  const { routes, redirectPath } = getRoutes(
    onboardingCompleted,
    quizzesCompleted,
    foodId
  );

  const routesToRender = { ...navRoutes.public, ...routes };

  useEffect(() => {
    const loadData = async () => {
      await Promise.all([
        dispatch(fetchcategories()),
        dispatch(fetchQuizzes()),
        dispatch(fetchTraits()),
        dispatch(fetchIdentities()),
        dispatch(fetchApproaches()),
        dispatch(fetchTasks()),
      ]);

      setIsLoading(false);
    };

    loadData();
  }, [dispatch]);

  /**
   * Set analytics user ID
   */
  useEffect(() => {
    if (!sidekickId) return;
    analytics.setUserId(sidekickId);
  }, [sidekickId]);

  /**
   * Log page views in Google Analytics
   */
  useEffect(() => {
    const foundRouteKey = Object.keys(routesToRender).find((k) => {
      const route = routesToRender[k as keyof typeof routesToRender];
      const path = route.path();
      return matchPath(location.pathname, { path, exact: true, strict: false });
    });

    const route =
      foundRouteKey &&
      routesToRender[foundRouteKey as keyof typeof routesToRender];

    if (!route) return;

    analytics.logScreenView(route.name, window.location.href);
  }, [location.pathname]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Switch>
      {Object.values(routesToRender).map((route, idx) => (
        <Route
          key={`route-${route.path()}_${idx}`}
          path={route.path()}
          {...(show404 && route.show404
            ? {
                render: ({ history }) => (
                  <ErrorScreen
                    error={{
                      title: "Oops! There's been an error :(",
                      description:
                        "We're having trouble retrieving the data you requested. Please refresh to try again, or click the button below to go back to the home screen.",
                    }}
                    onDismiss={() => {
                      history.push('/');
                      dispatch(hide404());
                    }}
                  />
                ),
              }
            : { component: route.component })}
        />
      ))}

      <Redirect to={redirectPath} />
    </Switch>
  );
};

const AppRouter: React.FC<{ history: History<unknown> }> = ({ history }) => {
  return (
    <Router history={history}>
      {/* <Sentry.ErrorBoundary fallback={FallbackError} showDialog> */}
      <ScrollToTop>
        <RoutesRenderer />
      </ScrollToTop>
      {/* </Sentry.ErrorBoundary> */}
    </Router>
  );
};

export default AppRouter;
