export const buildQueryParamsFromArray = (
  queryParams: string[],
  key: string
): string => {
  return queryParams.map((q) => `${key}=${q}`).join('&');
};

export const urls = {
  approaches: `/approaches/`,
  categories: '/categories',
  identities: `/identities/`,
  quizzes: `/quizzes/`,
  register: `/auth/local/register`,
  tasks: `/tasks`,
  traits: `/traits/`,
  user: (uid: string) => `/users/uid/${uid}`,
};
