import { extendTheme } from '@chakra-ui/react';
import themeColors from 'theme/colors';

const styles = {
  global: {
    body: {
      backgroundColor: 'brown.50',
    },
    p: {
      whiteSpace: 'pre-wrap',
    },
    a: {
      color: 'brand.600',
    },
  },
};

const colors = {
  brand: themeColors.darkGray,
  ...themeColors,
};

const textVariants = {
  script: {
    fontFamily: 'Lemonado',
    letterSpacing: '-0.02em',
    textTransform: 'uppercase',
    fontWeight: 400,
  },
};

const overrides = {
  styles,
  colors,
  fonts: {
    heading: 'Greed',
    body: 'Greed',
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: "bold",
        borderRadius: 30,
        textTransform: 'uppercase',
      },
      defaultProps: {
        colorScheme: 'darkGray',
      },
      variants: {
        outline: {
          borderWidth: 2,
        },
      },
    },
    Text: {
      variants: textVariants,
    },
    Heading: {
      variants: textVariants,
    },
  },
};

export default extendTheme(overrides);
