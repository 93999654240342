import React, { useState } from 'react';
import { AddIcon, ArrowForwardIcon, MinusIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  chakra,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Image,
  Input,
  Stack,
  Text,
  useCheckbox,
  useCheckboxGroup,
} from '@chakra-ui/react';

import bgImg from 'assets/svg/personalities.svg';
import arrow2 from 'assets/svg/arrows/s-arrow-117.svg';
import arrow4 from 'assets/svg/arrows/s-arrow-165.svg';
import arrow1 from 'assets/svg/arrows/s-arrow-99.svg';
import sausage from 'assets/svg/characters/sausage.svg';
import cabbage from 'assets/svg/characters/cabbage.svg';
import avocado from 'assets/svg/characters/avocado.svg';
import squiggle6 from 'assets/svg/squiggles/s-squiggle-105.svg';
import squiggle2 from 'assets/svg/squiggles/s-squiggle-131.svg';
import squiggle1 from 'assets/svg/squiggles/s-squiggle-144.svg';
import squiggle5 from 'assets/svg/squiggles/s-squiggle-157.svg';
import squiggle3 from 'assets/svg/squiggles/s-squiggle-168.svg';
import squiggle4 from 'assets/svg/squiggles/s-squiggle-96.svg';
import testimonials1 from 'assets/images/testimonials_1.png';
import testimonials2 from 'assets/images/testimonials_2.png';

import { useDispatch, useSelector } from 'redux/hooks';
import { completeOnboarding } from 'redux/slices/app';
import { FullCategory } from 'types';

const CustomCheckbox = (props: any) => {
  const { state, getInputProps, getLabelProps, htmlProps } = useCheckbox(props);
  const { limitReached = false } = props;

  const disabled = limitReached && !state.isChecked;
  return (
    <GridItem>
      <chakra.label
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        textTransform="uppercase"
        fontWeight="medium"
        rounded="full"
        px={4}
        py={3}
        cursor="pointer"
        borderWidth="2px"
        textAlign="center"
        w="100%"
        opacity={disabled ? 0.3 : 1}
        pointerEvents={disabled ? 'none' : 'auto'}
        {...(state.isChecked
          ? {
              backgroundColor: 'yellow.200',
              borderColor: 'transparent',
            }
          : {
              borderColor: 'darkGray.900',
            })}
        {...htmlProps}
      >
        <input {...getInputProps()} hidden />
        <Text {...getLabelProps()}>{props.value}</Text>
      </chakra.label>
    </GridItem>
  );
};

type StepProps = {
  happyWithFood: boolean;
  name: string;
  onNext: () => any;
  setHappyWithFood: (isHappy: boolean) => any;
  setName: (name: string) => any;
};

const Step1: React.FC<StepProps> = ({ onNext, setName: setNameProps }) => {
  const [name, setName] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  return (
    <>
      <Box>
        <Text fontSize={['xl', '2xl']} fontWeight="medium">
          Hi there!
        </Text>
        <Text fontSize={['xl', '2xl']} mt={0}>
          So to start, what's your name?
        </Text>
      </Box>
      <Box position="relative">
        <Image
          src={squiggle1}
          position="absolute"
          bottom={-10}
          left={10}
          right={10}
        />
        <Input
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            setNameProps(e.target.value);
          }}
          isInvalid={isInvalid}
          placeholder="Enter Name"
          textAlign="center"
          border="none"
          autoFocus
          focusBorderColor="white"
          fontSize={['3xl', '4xl']}
          fontWeight="medium"
          p={10}
          _placeholder={{
            color: 'brown.700',
            opacity: 0.4,
          }}
        />
      </Box>
      <Button
        rightIcon={<ArrowForwardIcon />}
        onClick={() => {
          if (name !== '') {
            setIsInvalid(false);
            localStorage.setItem('name', name);
            // setStep(step + 1);
            onNext();
          } else {
            setIsInvalid(true);
          }
        }}
        size="lg"
        colorScheme="yellow"
        disabled={name === ''}
        _disabled={{
          opacity: 0,
          pointerEvents: 'none',
        }}
      >
        Next
      </Button>
      <Image src={sausage} width="80%" />
    </>
  );
};

const Step2: React.FC<StepProps> = ({ name, onNext, setHappyWithFood }) => (
  <>
    <Box position="relative">
      <Image
        src={squiggle2}
        position="absolute"
        top={[-100, -150]}
        right={[-100, -200]}
        width={[200, 300]}
        transform="scaleX(-1)"
      />
      <Text fontSize={['xl', '2xl']}>
        {`Nice to meet you ${name.split(' ')[0]}.`}
      </Text>
      <Text fontSize={['xl', '2xl']}>So would you say you're currently</Text>
      <Text fontSize={['8xl', '9xl']} variant="script">
        100%
      </Text>
      <Text fontSize={['xl', '2xl']} fontWeight="medium">
        happy with every meal time
      </Text>
      <Text fontSize={['xl', '2xl']}>you experience each day of the week?</Text>
    </Box>
    <Stack
      direction={['column', 'row']}
      spacing={3}
      align="center"
      alignSelf="center"
      position="relative"
    >
      <Button
        size="lg"
        onClick={() => {
          setHappyWithFood(true);
          onNext();
        }}
        variant="outline"
      >
        Yes, I'm nailing it
      </Button>
      <Button
        size="lg"
        onClick={() => {
          setHappyWithFood(false);
          onNext();
        }}
        colorScheme="yellow"
      >
        Nah, it's not perfect
      </Button>
    </Stack>
  </>
);

const Step3: React.FC<StepProps> = ({ happyWithFood, onNext }) => (
  <>
    <Box>
      {!happyWithFood ? (
        <>
          <Text fontSize={['xl', '2xl']} mb={4}>
            <Text as="b">
              {"You won't be surprised to hear that the vast majority aren't! "}
            </Text>
            Perhaps you're lacking the time to cook or have an uber-tight budget
            to work with?
          </Text>
          <Text fontSize={['xl', '2xl']}>
            Whatever your reasons, we think we're really going to be able to
            help.
          </Text>
        </>
      ) : (
        <Text fontSize={['xl', '2xl']}>
          <Text as="b">
            {
              "Excellent to hear that you feel like you've nailed your cooking life. "
            }
          </Text>
          We reckon there are a few things we could uncover with you to take
          things to the next level.
        </Text>
      )}

      <Box position="relative">
        <Image
          src={squiggle3}
          position="absolute"
          top={-50}
          right={90}
          bottom={0}
          left={90}
          opacity={[0, 0, 1]}
          objectFit="contain"
          transform="scaleX(-1) rotate(35deg)"
        />
        <Text fontSize={['8xl', '9xl']} variant="script">
          87%
        </Text>
        <Text fontSize={['xl', '2xl']} maxWidth={550} mx="auto">
          {`of people who discovered their Cooking Personality confirmed it changed their behaviour and lifestyle positively.${
            happyWithFood ? " We'd really like to show you..." : ''
          }`}
        </Text>
      </Box>
    </Box>
    <Button size="lg" onClick={onNext} colorScheme="yellow">
      Next
    </Button>
  </>
);

const Step4: React.FC<StepProps> = ({ onNext }) => {
  const { value: goals, getCheckboxProps: goalsCheckboxProps } =
    useCheckboxGroup({
      defaultValue: [],
    });

  return (
    <>
      <Flex position="relative" justifyContent="center">
        <Image
          src={squiggle4}
          position="absolute"
          top={[-100, -200]}
          width={['50px', '100px']}
          transform={['translateX(80px)', 'translateX(120px)']}
        />
        <Text fontSize={['xl', '2xl']}>
          What do you feel you most need help with to <b>get more joy</b> out of
          your food?
        </Text>
      </Flex>

      <Box>
        <Text fontSize="xl" mb={4} fontWeight="medium">
          Select up to 3
        </Text>

        <Grid
          templateColumns={[
            'repeat(1, 1fr)',
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
          ]}
          gap={4}
        >
          <CustomCheckbox
            limitReached={goals.length >= 3}
            {...goalsCheckboxProps({ value: 'Budgeting' })}
          />
          <CustomCheckbox
            limitReached={goals.length >= 3}
            {...goalsCheckboxProps({ value: 'Cleaning Up' })}
          />
          <CustomCheckbox
            limitReached={goals.length >= 3}
            {...goalsCheckboxProps({ value: 'Food Shopping' })}
          />
          <CustomCheckbox
            limitReached={goals.length >= 3}
            {...goalsCheckboxProps({ value: 'Recipe Inspiration' })}
          />
          <CustomCheckbox
            limitReached={goals.length >= 3}
            {...goalsCheckboxProps({ value: 'Multitasking' })}
          />
          <CustomCheckbox
            limitReached={goals.length >= 3}
            {...goalsCheckboxProps({ value: 'Nutrition' })}
          />
          <CustomCheckbox
            limitReached={goals.length >= 3}
            {...goalsCheckboxProps({ value: 'Cooking Skills' })}
          />
          <CustomCheckbox
            limitReached={goals.length >= 3}
            {...goalsCheckboxProps({ value: 'Meal Planning' })}
          />
          <CustomCheckbox
            limitReached={goals.length >= 3}
            {...goalsCheckboxProps({ value: 'Other' })}
          />
        </Grid>
      </Box>
      <Button
        rightIcon={<ArrowForwardIcon />}
        onClick={() => {
          // TODO: Send this data to the backend
          localStorage.setItem('goals', JSON.stringify(goals));
          onNext();
        }}
        size="lg"
        colorScheme="yellow"
        disabled={goals.length === 0}
        _disabled={{
          opacity: 0,
          pointerEvents: 'none',
        }}
      >
        Next
      </Button>
    </>
  );
};

const Step5: React.FC<StepProps> = ({ onNext }) => {
  const { value: feelings, getCheckboxProps: feelingsCheckboxProps } =
    useCheckboxGroup({
      defaultValue: [],
    });
  return (
    <>
      <Text fontSize={['xl', '2xl']}>
        Discovering your Cooking Personality is all about unlocking brilliant
        weekly food habits and reigniting your passion for food.
      </Text>
      <Text fontSize={['xl', '2xl']}>
        Which feeling would you like to connect with most at mealtimes?
      </Text>
      <Box position="relative">
        <Image
          src={squiggle5}
          position="absolute"
          top={[0, -10, -20]}
          right={[-115, -125, -220]}
          width={['200px', '200px', '300px']}
        />
        <Text fontSize="xl" mb={4} fontWeight="medium">
          Select 1
        </Text>

        <Grid
          templateColumns={[
            'repeat(1, 1fr)',
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
          ]}
          gap={4}
        >
          <CustomCheckbox
            limitReached={feelings.length >= 1}
            {...feelingsCheckboxProps({ value: 'Happiness' })}
          />
          <CustomCheckbox
            limitReached={feelings.length >= 1}
            {...feelingsCheckboxProps({ value: 'Nourishment' })}
          />
          <CustomCheckbox
            limitReached={feelings.length >= 1}
            {...feelingsCheckboxProps({ value: 'Relaxation' })}
          />
          <CustomCheckbox
            limitReached={feelings.length >= 1}
            {...feelingsCheckboxProps({ value: 'Excitement' })}
          />
          <CustomCheckbox
            limitReached={feelings.length >= 1}
            {...feelingsCheckboxProps({ value: 'Discovery' })}
          />
          <CustomCheckbox
            limitReached={feelings.length >= 1}
            {...feelingsCheckboxProps({ value: 'Other' })}
          />
        </Grid>
      </Box>
      <Button
        rightIcon={<ArrowForwardIcon />}
        onClick={() => {
          // TODO: Send this data to the backend
          localStorage.setItem('feelings', JSON.stringify(feelings[0]));
          onNext();
        }}
        size="lg"
        colorScheme="yellow"
        disabled={feelings.length === 0}
        _disabled={{
          opacity: 0,
          pointerEvents: 'none',
        }}
      >
        Next
      </Button>
    </>
  );
};

const Testimonial1: React.FC<StepProps> = ({ onNext }) => (
  <>
    <Flex
      backgroundColor="red.200"
      borderRadius="3xl"
      maxW={['90vw', '90vw', '60vw']}
      p="10"
      pb={['10', '10', '16']}
      position="relative"
      pr={['10', '10', '20']}
    >
      <Box pos="relative" mr="2">
        <Image src={testimonials1} minW={[8, 8, 10]} h={[8, 8, 10]} />
        <Image
          display={['none', 'none', 'flex']}
          minH={250}
          minW={250}
          pos="absolute"
          right={-10}
          src={arrow2}
          style={{ transform: 'scaleX(-1)' }}
          top={-10}
        />
      </Box>

      <Flex flexDir="column">
        <Text textAlign="left" fontSize={['lg', 'xl']}>
          Right. 'Fess up. Do you have a camera in my kitchen? I'm a Lenny and
          everything is spot on, my neck hurts from noding in agreement to
          Every. Single. Statement.
        </Text>
        <Text textAlign="left" fontWeight="bold" fontSize={['md', 'lg']}>
          bindii_p
        </Text>
      </Flex>
      <Image
        bottom={-50}
        display={['none', 'none', 'flex']}
        height={200}
        position="absolute"
        right={-75}
        src={cabbage}
        width={200}
      />
    </Flex>
    <Button
      rightIcon={<ArrowForwardIcon />}
      onClick={() => {
        onNext();
      }}
      size="lg"
      colorScheme="yellow"
    >
      Next
    </Button>
  </>
);

const Testimonial2: React.FC<StepProps> = ({ onNext }) => (
  <>
    <Flex
      backgroundColor="cyan.200"
      borderRadius="3xl"
      maxW={['90vw', '90vw', '60vw']}
      p="10"
      pb={['10', '10', '16']}
      position="relative"
      pr={['10', '10', '20']}
    >
      <Box pos="relative" mr="2">
        <Image src={testimonials2} minW={[8, 8, 10]} h={[8, 8, 10]} />
        <Image
          display={['none', 'none', 'flex']}
          minH={200}
          minW={200}
          pos="absolute"
          right={-8}
          src={arrow1}
          bottom={120}
        />
      </Box>

      <Flex flexDir="column">
        <Text textAlign="left" fontSize={['lg', 'xl']}>
          Aiva here, loved this test, really opened my eyes to how and why I
          cook. Cooking for three every day whilst training to up the protein
          and be environmentally friendly is tricky... gonna see what journey I
          can go on to develop! 🙌
        </Text>
        <Text textAlign="left" fontWeight="bold" fontSize={['md', 'lg']}>
          ronreville
        </Text>
      </Flex>
      <Image
        bottom={-50}
        display={['none', 'none', 'flex']}
        height={200}
        position="absolute"
        right={-75}
        src={avocado}
        width={200}
      />
    </Flex>
    <Button
      rightIcon={<ArrowForwardIcon />}
      onClick={() => {
        onNext();
      }}
      size="lg"
      colorScheme="yellow"
    >
      Next
    </Button>
  </>
);

const Step7: React.FC<StepProps> = ({ onNext }) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [friends, setFriends] = useState([] as string[]);
  return (
    <>
      <Text fontSize={['xl', '2xl']}>
        The enjoyment of food is also majorly affected by your social
        surroundings.
      </Text>
      <Text fontSize={['xl', '2xl']}>Who do you normally cook for?</Text>
      <Flex
        alignItems="center"
        justifyContent="center"
        maxWidth="100%"
        flexWrap="wrap"
      >
        {friends.map((friend, index) => (
          <Flex alignItems="center">
            {index > 0 && (
              <Text fontSize={['3xl', '4xl']} fontWeight="medium">
                {'&'}
              </Text>
            )}
            <Input
              value={friend}
              isInvalid={friend === '' && isInvalid}
              onChange={(e) => {
                const newFriends = [...friends];
                newFriends[index] = e.target.value;
                setFriends(newFriends);
              }}
              placeholder="Enter Name"
              textAlign="center"
              border="none"
              focusBorderColor="white"
              fontSize={['3xl', '4xl']}
              fontWeight="medium"
              py={10}
              _placeholder={{
                color: 'brown.700',
                opacity: 0.4,
              }}
              w={friend ? `${friend.length + 2}ch` : '11ch'}
              autoFocus
            />
          </Flex>
        ))}
      </Flex>
      <Stack
        direction={['column', 'row']}
        spacing={3}
        align="center"
        alignSelf="center"
        position="relative"
      >
        <Image
          src={arrow2}
          position="absolute"
          top={['auto', '-60px']}
          bottom={[-162, 'auto']}
          right={[-140, -210]}
          width={['200px', '300px']}
          maxWidth="300px"
        />
        {friends.length === 0 && (
          <Button onClick={onNext} size="lg" variant="outline">
            Just Me
          </Button>
        )}
        {friends.length > 0 && (
          <IconButton
            aria-label="Remove"
            icon={<MinusIcon />}
            variant="outline"
            size="lg"
            onClick={() => {
              const newFriends = [...friends];
              newFriends.pop();
              setFriends(newFriends);
            }}
          />
        )}
        <Button
          rightIcon={<AddIcon />}
          onClick={() => setFriends([...friends, ''])}
          size="lg"
          disabled={friends.length > 0 && friends.includes('')}
          _disabled={{
            opacity: 0.5,
            pointerEvents: 'none',
          }}
        >
          Add Person
        </Button>
        {friends.length > 0 && (
          <Button
            rightIcon={<ArrowForwardIcon />}
            onClick={() => {
              if (!friends.includes('')) {
                setIsInvalid(false);
                localStorage.setItem('friends', JSON.stringify(friends));
                onNext();
              } else {
                setIsInvalid(true);
              }
            }}
            size="lg"
            colorScheme="yellow"
            disabled={friends.length > 0 && friends.includes('')}
            _disabled={{
              opacity: 0.5,
              pointerEvents: 'none',
            }}
          >
            That's Everyone
          </Button>
        )}
      </Stack>
    </>
  );
};

const Step8: React.FC<StepProps> = ({ onNext }) => {
  const [categoryStep, setCategoryStep] = useState<number | undefined>();
  const [friendName, setFriendName] = useState('');

  const categoryFriendMappingStr =
    localStorage.getItem('categoryFriendMapping') || '{}';

  const categoryFriendMapping = JSON.parse(categoryFriendMappingStr);

  const categories = useSelector((state) =>
    Object.values(state.categories.entities as Record<string, FullCategory>)
  );

  if (categoryStep === undefined) {
    return (
      <Flex
        alignItems="center"
        w="100vw"
        flexDir={['column', 'column', 'column', 'row']}
      >
        <Flex
          w={['100%', '100%', '100%', '50%']}
          flexDir="column"
          pl={['4', '4', '4', '44']}
          pr={['4', '4', '4', '0']}
          alignItems={['center', 'center', 'center', 'flex-start']}
        >
          <Heading textAlign={['center', 'center', 'center', 'left']}>
            Cooking personalities fall into 4 groups
          </Heading>
          <Text my="6">Do they remind you of anyone?</Text>
          <Button
            rightIcon={<ArrowForwardIcon />}
            onClick={() => setCategoryStep(0)}
            size="lg"
            colorScheme="yellow"
            mt="4"
          >
            MEET THE GANG!
          </Button>
        </Flex>

        <Flex
          w={['90%', '90%', '90%', '50%']}
          h="70vh"
          backgroundImage={bgImg}
          mt={['8', '8', '8', '0']}
          backgroundSize={['contain', 'contain', 'contain', 'cover']}
          backgroundRepeat="no-repeat"
        />
      </Flex>
    );
  }

  const category = categories[categoryStep];
  const { id: categoryId, summary, identities, color, name } = category;

  const handleOnClickNext = () =>
    categoryStep + 1 === categories.length
      ? onNext()
      : setCategoryStep(categoryStep + 1);

  return (
    <Flex
      justifyContent="center"
      flexDir="column"
      backgroundColor={`${color}.200`}
      width="100vw"
      height="100vh"
      px={['6', '10', '40']}
    >
      <Heading display="inline-flex" mb="2">
        The
        <Text ml="2" as="h2" textTransform="uppercase">{`${name}s`}</Text>
      </Heading>
      <Text textAlign="left" mb="8">
        {summary}
      </Text>
      <Grid
        templateColumns={['repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(4, 1fr)']}
        gap={4}
      >
        {identities.map((id) => (
          <GridItem
            display="flex"
            px="1"
            flexDirection="column"
            justifyContent="flex-end"
          >
            <Image
              position="relative"
              src={id.imageUrl}
              alt={id.name}
              w="80%"
              maxWidth="500px"
              height={['100px', '200px', '200px', '250px']}
              mx="auto"
              mb="2"
            />
            <Heading variant="script" fontSize={['2xl', '2xl', '2xl', '3xl']}>
              {id.character}
            </Heading>
            <Text fontSize={['sm', 'sm', 'sm', 'md']} pb={4} mt="-2">
              {id.name}
            </Text>
          </GridItem>
        ))}
      </Grid>
      <Flex mt="8" flexDirection={['column', 'column', 'row']}>
        <Flex alignItems="center" flexDirection={['column', 'column', 'row']}>
          <Text bold whiteSpace="nowrap">
            Who does this remind you of?
          </Text>
          <Input
            value={friendName}
            onChange={(e) => {
              setFriendName(e.target.value);
            }}
            placeholder="Enter Name"
            textAlign="center"
            border="none"
            autoFocus
            _focus={{ border: 'none' }}
            fontSize={['1xl', '2xl']}
            fontWeight="medium"
            _placeholder={{
              color: 'brown.700',
              opacity: 0.4,
            }}
          />
        </Flex>
        <Flex
          flexGrow={1}
          justifyContent={['center', 'center', 'flex-end']}
          mt={['4', '4', '0']}
        >
          <Button
            onClick={() => {
              if (friendName) {
                categoryFriendMapping[categoryId] = friendName;
                localStorage.setItem(
                  'categoryFriendMapping',
                  JSON.stringify(categoryFriendMapping)
                );
              }
              setFriendName('');
              handleOnClickNext();
            }}
          >
            NEXT
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

const Step9: React.FC<StepProps> = ({ onNext }) => (
  <>
    <Box>
      <Text fontSize={['xl', '2xl']} mb={4}>
        Now we've got that initial info from you, we're ready to take you to
        your tests.
      </Text>
      <Text fontSize={['xl', '2xl']}>
        There are <b>6 key areas</b> we're going to focus in on, and each one
        will help to analyse your approach to your foodie routine.
      </Text>
    </Box>
    <Box position="relative">
      <Image
        src={arrow4}
        position="absolute"
        bottom={[-200, -180]}
        left={[-75, -270]}
        width={['250px', '350px']}
        maxWidth="500px"
        transform={['scaleY(-1) rotate(90deg)', 'scaleY(-1) rotate(40deg)']}
      />
      <Button
        rightIcon={<ArrowForwardIcon />}
        onClick={onNext}
        size="lg"
        colorScheme="yellow"
      >
        Gotcha
      </Button>
    </Box>
  </>
);

const Step10: React.FC<StepProps> = () => {
  const dispatch = useDispatch();
  return (
    <>
      <Box>
        <Text fontSize={['xl', '2xl']} mb={4}>
          This in turn will provide you with your Cooking Personality! Your
          results will provide a detailed summary of recommendations and set you
          up with the first of your monthly food challenges.
        </Text>
        <Text fontWeight="medium" fontSize={['xl', '2xl']}>
          There's a new you on the horizon... Are you ready?
        </Text>
      </Box>
      <Box position="relative">
        <Image
          src={squiggle6}
          position="absolute"
          bottom={[-130, -200]}
          right={[-30, -50]}
          width={['100px', '150px']}
          maxWidth="500px"
          transform="scaleY(-1) rotate(90deg)"
        />
        <Button
          rightIcon={<ArrowForwardIcon />}
          onClick={() => {
            // After dispatching completeOnboarding action, the user is automatically taken
            // to navRoutes.quizzes.myTests - see route rendering logic in AppRouter
            dispatch(completeOnboarding());
          }}
          size="lg"
          colorScheme="yellow"
        >
          Let's Do This
        </Button>
      </Box>
    </>
  );
};

const steps = [
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Testimonial1,
  Testimonial2,
  Step7,
  Step8,
  Step9,
  Step10,
];

export default steps;
