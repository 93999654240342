import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {
  approachesReducer,
  bucketsReducer,
  categoriesReducer,
  identitiesReducer,
  questionsReducer,
  quizzesReducer,
  tasksReducer,
  traitsReducer,
} from 'redux/slices/quiz';
import { userReducer } from 'redux/slices/users';
import { appReducer } from 'redux/slices/app';

export const store = configureStore({
  reducer: {
    app: appReducer,
    categories: categoriesReducer,
    quizzes: quizzesReducer,
    questions: questionsReducer,
    buckets: bucketsReducer,
    traits: traitsReducer,
    identities: identitiesReducer,
    approaches: approachesReducer,
    tasks: tasksReducer,
    user: userReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
