import { useEffect, useState } from 'react';
import { useSelector } from 'redux/hooks';
import { SHORT_SLUGS } from 'constants/quiz';
import isEmpty from 'lodash/isEmpty';
import {
  SocialBucketOptions,
  BucketOptions,
  UserResult,
  Quiz,
  Trait,
  Slugs,
  ComputedResult,
} from 'types';

export const useQuizzesProgress = (isLoading: boolean = false) => {
  const [userResults, setUserResults] = useState([] as ComputedResult[]);

  const quizzes = useSelector((state) => state.quizzes.entities);
  const traits = useSelector((state) => state.traits.entities);

  const quizzesArr = Object.values(quizzes) as Quiz[];

  useEffect(() => {
    if (isLoading) return;

    if (!quizzesArr.length || isEmpty(traits)) return;

    const userResults = getUserResultsFromStorage(
      quizzesArr,
      Object.values(traits) as Trait[]
    );

    setUserResults(userResults);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizzesArr.length, isLoading]);

  const completeResults = userResults.filter((ur) =>
    Boolean(ur.traitId && traits[ur.traitId])
  );

  const remainingResults = userResults.filter(
    (ur) => !(ur.traitId && traits[ur.traitId])
  );
  const remainingQuizzes = remainingResults.map(
    (rr) => quizzes[rr.slug]
  ) as Quiz[];

  const numQuizzes = Object.keys(quizzes).length;
  const numUserResults = Math.min(numQuizzes, completeResults.length);
  const numRemainingQuizzes = numQuizzes - numUserResults;

  const quizzesCompleted = numQuizzes === numUserResults;

  return {
    quizzesCompleted,
    completeResults,
    numQuizzes,
    numUserResults,
    numRemainingQuizzes,
    remainingQuizzes,
    userResults,
  };
};

export const getTrait = (
  quiz: Quiz,
  traits: Trait[],
  userResult: UserResult
) => {
  // Use the user's result tallies to assign them to
  // traits XA or XB and YA or YB
  let xTrait = '' as BucketOptions;
  let yTrait = '' as BucketOptions | SocialBucketOptions;
  if (userResult) {
    if (userResult.tally.XA > userResult.tally.XB) {
      xTrait = 'XA';
    } else {
      xTrait = 'XB';
    }

    if (quiz?.slug === 'social') {
      // On the "social" quiz, the trait is determined by the number of people in the household
      const friendsStr = localStorage.getItem('friends');
      const friends = friendsStr ? JSON.parse(friendsStr) : [];
      const numPeople = friends.length + 1;
      if (numPeople === 1) {
        yTrait = '_1';
      } else if (numPeople === 2) {
        yTrait = '_2';
      } else {
        yTrait = '_3';
      }
    } else if (userResult.tally.YA > userResult.tally.YB) {
      yTrait = 'YA';
    } else {
      yTrait = 'YB';
    }
  }

  // Grab the trait details that match the users X/Y combination
  // (assume there are details avilable for every possible combination)
  const quizTraits = traits.filter((trait) => trait.quiz === quiz?.slug);

  const trait = quizTraits?.find(
    (trait) =>
      trait.bucketCombination.includes(xTrait) &&
      trait.bucketCombination.includes(yTrait)
  );
  return trait;
};

export const getUserResultsFromStorage = (
  quizzes: Quiz[],
  traits: Trait[]
): ComputedResult[] => {
  return quizzes.map((quiz) => {
    const userResultStr = localStorage.getItem(`${quiz.slug}-result`);
    const userResult = userResultStr ? JSON.parse(userResultStr) : null;

    const trait = userResult
      ? (getTrait(quiz, traits, userResult) as Trait)
      : null;

    return {
      slug: quiz.slug,
      tally: userResult ? userResult.tally : undefined,
      traitId: trait ? trait.id : undefined,
    };
  });
};

export const getUserResultsFromString = (
  quizzes: Quiz[],
  traits: Trait[],
  idString: string
): ComputedResult[] => {
  // Convert minified ID string back to userResults object
  const ids = idString.split('&');
  return ids
    .filter((id) => !!id)
    .map((id) => {
      const shortSlug = id.split('=')[0];
      const value = id.split('=')[1];
      const allSlugs = Object.keys(SHORT_SLUGS) as Slugs[];
      const slug = allSlugs.find(
        (key) => SHORT_SLUGS[key] === shortSlug
      ) as Slugs;
      const quiz = quizzes.find((q) => q.slug === slug) as Quiz;

      const tally = {
        XA: 0,
        XB: 0,
        YA: 0,
        YB: 0,
      };
      if (value.charAt(0) === 'a') {
        tally['XA'] = 1;
      } else {
        tally['XB'] = 1;
      }
      if (value.charAt(1) === 'a') {
        tally['YA'] = 1;
      } else {
        tally['YB'] = 1;
      }

      const userResult: UserResult = {
        slug,
        tally,
      };
      const trait = getTrait(quiz, traits, userResult) as Trait;
      return {
        slug: quiz.slug,
        tally: userResult ? userResult.tally : undefined,
        traitId: trait ? trait.id : undefined,
      };
    });
};

/**
 * Create a compact ID string of all results
 * to be used for shareable URLs
 * TODO: Replace this with backend fetch – this oversimplifies reults to binary
 * this or that' rather than saving tallies for '% this vs % that' results
 * @param userResults
 * @param traits
 * @returns  string
 */
export const minifyUserResults = (
  userResults: ComputedResult[],
  traits: Trait[]
): string =>
  userResults.reduce((acc, ur) => {
    const bucketCombination = traits.find(
      (t) => t.id === ur.traitId
    )?.bucketCombination;

    if (!bucketCombination) return acc;

    const shortSlug = SHORT_SLUGS[ur.slug];

    const [first, second] = bucketCombination;

    const xValue = first === 'XA' ? 'a' : 'b';
    const yValue = second === 'YA' ? 'a' : second === 'YB' ? 'b' : second;

    return `${acc}${shortSlug}=${xValue}${yValue}&`;
  }, '');
