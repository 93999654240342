import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import passwordGenerator from 'generate-password';
import { use100vh } from 'react-div-100vh';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { AddIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  Heading,
  HStack,
  Input,
  Link,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';

import navRoutes from 'navigation/Routes';
import { useDispatch } from 'redux/hooks';
import { register } from 'redux/slices/auth';
import { setFoodId } from 'redux/slices/app';
import { RootState } from 'redux/store';

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux, RouteComponentProps {}

const EmailResults: React.FC<Props> = ({ history }) => {
  const [friendNames, setFriendNames] = useState<string[]>([]);
  const [myEmail, setMyEmail] = useState('');
  const [friends, setFriends] = useState<{ name: string; email: string }[]>([]);

  const [termsAccepted, setTermsAccepted] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const friendsStr = localStorage.getItem('friends');
    const friends = friendsStr ? JSON.parse(friendsStr) : [];
    setFriendNames(friends);
  }, []);

  const vHeight = use100vh();
  const screenHeight = vHeight ? `${vHeight}px` : '100vh';

  let friendsText = '';
  friendNames.map((friend, index) => {
    return (friendsText += `${friend}${
      index + 1 < friendNames.length - 1
        ? ', '
        : index + 1 === friendNames.length - 1
        ? ' and '
        : ''
    }`);
  });

  const onSubmit = async () => {
    // TODO: Hook this up properly to send email on button press
    // and make sure 'goals', 'feelings' and 'numPeople' from the 'about-me' flow
    // are all assigned to this user on the backend
    const name = localStorage.getItem('name') || '';
    const userResultsStr = localStorage.getItem('userResults');
    const userResults = userResultsStr ? JSON.parse(userResultsStr) : [];

    const password = passwordGenerator.generate({
      numbers: true,
      symbols: true,
      excludeSimilarCharacters: true,
    });

    await dispatch(
      register({
        name,
        email: myEmail,
        password,
        results: userResults,
        friends,
      })
    )
      .unwrap()
      .then(({ data: { user } }) => {
        localStorage.setItem('food_id', user.foodUid);
        dispatch(setFoodId(user.foodUid));
        history.push(navRoutes.public.foodId.path(user.foodUid));
      })
      .catch((err) => {
        // TODO : add error handling here
      });
  };

  return (
    <Flex bg="brown.50" minH={screenHeight} alignItems="center">
      <Container maxW="3xl">
        <Formik initialValues={{}} onSubmit={onSubmit}>
          <Form>
            <Stack
              as={Box}
              textAlign="center"
              alignItems="center"
              spacing={{ base: 8 }}
              py={{ base: 20, md: 36 }}
            >
              <Text fontSize={['xl', '2xl']}>
                In order to get your results, we will need to email you a unique
                link to view your Cooking Personality and food challenges.
              </Text>
              {friendNames.length ? (
                <Text fontSize={['xl', '2xl']}>
                  {`Send your results to ${friendsText} and anyone else who might be interested!`}
                </Text>
              ) : (
                <Text fontSize={['xl', '2xl']}>
                  Enter your email and send your results to anyone who might be
                  interested!
                </Text>
              )}
              <Box>
                <FormControl isRequired>
                  <Input
                    type="email"
                    value={myEmail}
                    onChange={(e) => setMyEmail(e.target.value)}
                    placeholder="Your Email Address"
                    textAlign="center"
                    border="none"
                    focusBorderColor="white"
                    fontSize={['3xl', '4xl']}
                    fontWeight="medium"
                    p={8}
                    mb={[2, 4]}
                    _placeholder={{
                      color: 'brown.700',
                      opacity: 0.4,
                    }}
                  />
                </FormControl>
                {friendNames.map((friend, index) => (
                  <Input
                    type="email"
                    value={(friends[index] && friends[index].email) || ''}
                    onChange={(e) => {
                      const newFriends = [...friends];
                      newFriends[index] = {
                        name: friend,
                        email: e.target.value,
                      };
                      setFriends(newFriends);
                    }}
                    placeholder={
                      friend
                        ? `${friend}${
                            friend.charAt(friend.length - 1) === 's'
                              ? "'"
                              : "'s"
                          } Email (Optional)`
                        : 'Enter email'
                    }
                    textAlign="center"
                    border="none"
                    focusBorderColor="white"
                    fontSize={['3xl', '4xl']}
                    fontWeight="medium"
                    p={8}
                    mb={[2, 4]}
                    _placeholder={{
                      color: 'brown.700',
                      opacity: 0.4,
                    }}
                  />
                ))}
                <Button
                  rightIcon={<AddIcon m="0" />}
                  onClick={() => setFriendNames([...friendNames, ''])}
                  size="lg"
                  // disabled={friendNames?.includes('')}
                  // _disabled={{ opacity: 0.5, pointerEvents: 'none' }}
                >
                  Add Friend's Email
                </Button>
              </Box>
              <HStack>
                <VStack>
                  <Heading fontSize="lg">Email Preferences</Heading>
                  <Checkbox
                    onChange={(e) => setTermsAccepted(e.currentTarget.checked)}
                  >
                    <Text lineHeight={0.5}>
                      I would like to be sent my results and receive
                    </Text>
                  </Checkbox>
                  <Text lineHeight={0.5}>
                    emails to support my experience on this website.
                  </Text>
                  <Text pt="4">You can unsubscribe at any time.</Text>
                  <Text pt="4" lineHeight={0.8}>
                    By clicking Send Results you confirm that you agree to our
                  </Text>
                  <Text lineHeight={0.8}>
                    <Link
                      href="https://sortedfood.com/privacy-policy/"
                      target="_blank"
                    >
                      Website Terms and Privacy Policy.
                    </Link>
                  </Text>
                </VStack>
              </HStack>
              <Button
                rightIcon={<ArrowForwardIcon />}
                colorScheme="yellow"
                size="lg"
                type="submit"
                disabled={!termsAccepted}
              >
                Send Results
              </Button>
              {/* TODO: Below explains frontend-based URL system (using base64 data in URL), replace this completely with backend fetching */}
              {/* <Text fontSize="xs">
                {
                  "Dev note – Emails aren't hooked up yet, but here's the unique link to your Sorted ID: "
                }
                <Link href={foodIdUrl} target="_blank">
                  {foodIdUrl}
                </Link>
                {" (It'll just say something like 'Click here' in the email)"}
              </Text> */}
            </Stack>
          </Form>
        </Formik>
      </Container>
    </Flex>
  );
};

const mapStateToProps = (state: RootState) => {
  return {};
};

const connector = connect(mapStateToProps);

export default connector(EmailResults);
