import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { analytics } from 'utils';

import './index.css';
import App from './App';

import * as serviceWorker from './serviceWorker';
import './assets/fonts/greed-medium.otf';
import './assets/fonts/greed-regular.otf';
import './assets/fonts/greed-semibold.otf';
import './assets/fonts/melvastype-lemonado-script-textured-italic.otf';

// Initialise Sentry SDK if in production
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    normalizeDepth: 5,
  });
}

analytics.initAmplitude();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
