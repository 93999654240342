import React from 'react';
import { useSelector } from 'redux/hooks';
import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';

import { Bucket, ComputedResult, Quiz } from 'types';

export const BucketItem: React.FC<{
  label: string;
  color?: string;
  hoverColor?: string;
}> = ({ label, color, hoverColor }) => (
  <Flex
    mx={2}
    mb={4}
    textAlign="center"
    flexDirection="column"
    alignItems="center"
    bg={color || 'auto'}
    borderWidth={2}
    borderColor={color ? 'transparent' : 'darkGray.900'}
    py={1}
    px={4}
    borderRadius="full"
    cursor={hoverColor ? 'pointer' : 'cursor'}
    _hover={{ bg: hoverColor || color }}
    transition="background 0.3s"
  >
    <Text fontWeight="medium">{label}</Text>
  </Flex>
);

const BucketPopupBody: React.FC<{
  traitLabel: string;
  color: string;
  traitTally?: number;
  oppositeLabel?: string;
  oppositeTally?: number;
}> = ({ traitLabel, color, traitTally, oppositeLabel, oppositeTally }) => {
  const percentageTrait =
    traitTally !== undefined && oppositeTally !== undefined
      ? Math.round(100 * (traitTally / (traitTally + oppositeTally)))
      : undefined;

  return (
    <>
      <Flex mb={5} justifyContent="center">
        <CircularProgress
          value={percentageTrait}
          color={`${color}.200`}
          trackColor="white"
          thickness={12}
          size={120}
        >
          <CircularProgressLabel fontFamily="Lemonado">{`${percentageTrait}%`}</CircularProgressLabel>
        </CircularProgress>
      </Flex>
      <Box mx={-2} mb={-4}>
        <BucketItem label={`${traitLabel}`} color={`${color}.200`} />
      </Box>
      <Text textAlign="center" mb={0} fontWeight="semibold">
        vs
      </Text>
      <Box mx={-2} mb={-2}>
        <BucketItem label={`${oppositeLabel}`} color="white" />
      </Box>
    </>
  );
};

export const BucketPopup: React.FC<{
  traitLabel: string;
  color: string;
  quizName: string;
  traitTally?: number;
  oppositeLabel?: string;
  oppositeTally?: number;
  showPopupOnly?: boolean;
}> = ({
  traitLabel,
  color,
  quizName,
  traitTally,
  oppositeLabel,
  oppositeTally,
  showPopupOnly,
}) => {
  if (showPopupOnly) {
    if (!oppositeLabel) return null;
    return (
      <Box
        bg={`${color}.50`}
        borderColor={`${color}.50`}
        borderRadius={10}
        p={[3, 6]}
        m={2}
      >
        <BucketPopupBody
          traitLabel={traitLabel}
          color={color}
          traitTally={traitTally}
          oppositeLabel={oppositeLabel}
          oppositeTally={oppositeTally}
        />
      </Box>
    );
  }
  return (
    <>
      <Popover placement="bottom">
        <PopoverTrigger>
          <Box>
            <BucketItem
              label={traitLabel}
              hoverColor={oppositeLabel ? `${color}.50` : undefined}
            />
          </Box>
        </PopoverTrigger>
        {oppositeLabel ? (
          <PopoverContent
            bg={`${color}.50`}
            borderColor={`${color}.50`}
            borderRadius={10}
            w={280}
          >
            <PopoverArrow bg={`${color}.50`} boxShadow="none!important" />
            <PopoverCloseButton mt={2} />
            <PopoverHeader borderBottom="none">
              <Text mt={2} textAlign="center" fontWeight="semibold">
                {quizName}
              </Text>
            </PopoverHeader>
            <PopoverBody>
              <BucketPopupBody
                traitLabel={traitLabel}
                color={color}
                traitTally={traitTally}
                oppositeLabel={oppositeLabel}
                oppositeTally={oppositeTally}
              />
            </PopoverBody>
          </PopoverContent>
        ) : null}
      </Popover>
    </>
  );
};

export const BucketList: React.FC<{
  results: ComputedResult[];
  showPopupsOnly?: boolean;
}> = ({ results, showPopupsOnly }) => {
  const traits = useSelector((state) => state.traits.entities);
  const buckets = useSelector((state) => state.buckets.entities);
  const quizzes = useSelector((state) => state.quizzes.entities);

  let items: {
    traitBucket: Bucket;
    traitTally?: number;
    oppositeBucket?: Bucket;
    oppositeTally?: number;
    quiz?: Quiz;
  }[] = [];

  results.map((ur) => {
    const trait = traits[ur.traitId as string];

    trait?.bucketCombination.map((traitBucketCategory) => {
      const traitBucket = Object.values(buckets).find(
        (bucket) =>
          bucket?.category === traitBucketCategory &&
          bucket?.quiz === trait.quiz
      );
      const traitTally = ur.tally && ur.tally[traitBucketCategory];

      const oppositeBucketCategory =
        traitBucketCategory === 'XA'
          ? 'XB'
          : traitBucketCategory === 'XB'
          ? 'XA'
          : traitBucketCategory === 'YA'
          ? 'YB'
          : traitBucketCategory === 'YB'
          ? 'YA'
          : undefined;

      const oppositeBucket = Object.values(buckets).find(
        (bucket) =>
          bucket?.category === oppositeBucketCategory &&
          bucket?.quiz === trait.quiz
      );
      const oppositeTally =
        ur.tally && oppositeBucketCategory && ur.tally[oppositeBucketCategory];

      const quiz = quizzes[trait.quiz];

      if (traitBucket) {
        items = [
          ...items,
          { traitBucket, traitTally, oppositeBucket, oppositeTally, quiz },
        ];
      }
    });
  });

  return (
    <>
      {items.map((item) => {
        return (
          <BucketPopup
            key={`${item.traitBucket.id}`}
            traitLabel={item.traitBucket.label}
            traitTally={item.traitTally}
            oppositeLabel={item.oppositeBucket?.label}
            oppositeTally={item.oppositeTally}
            color={item.quiz?.color || 'brand'}
            quizName={item.quiz?.name || ''}
            showPopupOnly={showPopupsOnly}
          />
        );
      })}
    </>
  );
};
