import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { RouteComponentProps } from 'react-router-dom';
import {
  Heading,
  Container,
  Text,
  Button,
  Stack,
  Box,
  Flex,
  IconButton,
  Collapse,
  Image,
  useTheme,
} from '@chakra-ui/react';
import {
  ArrowForwardIcon,
  ArrowBackIcon,
  AddIcon,
  MinusIcon,
} from '@chakra-ui/icons';
import dayjs from 'dayjs';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

import navRoutes from 'navigation/Routes';

import { ComputedResult, Quiz, Bucket, Task } from 'types';
import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'redux/hooks';

import { retrieveUser } from 'redux/slices/users';

import { CurvedSectionBreak } from 'components/svg';
import { BucketItem } from 'components/BucketList';
import Loading from 'components/Loading';

// Format to be used by dayjs functions
const MONTH_FORMAT = 'YYYY-MM';
const DISPLAY_DATE_FORMAT = 'MMMM YYYY';
// Earliest month from which tasks are available
const LAUNCH_DATE = 'June 2022';

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

type PropsFromRedux = ConnectedProps<typeof connector>;

// Routing Props
interface MatchParams {
  id?: string;
}

interface OwnProps extends RouteComponentProps<MatchParams> {}

interface Props extends OwnProps, PropsFromRedux {}

const TaskItem: React.FC<{
  index: number;
  task: Task;
  isExpanded: boolean;
  setIsExpanded: (e: boolean) => void;
  color: string;
  quiz: Quiz;
  bucket: Bucket;
}> = ({ index, task, isExpanded, setIsExpanded, color, quiz, bucket }) => {
  return (
    <>
      <Box
        bg="white"
        borderRadius="2xl"
        overflow="hidden"
        textAlign="left"
        w="100%"
        zIndex={0}
      >
        <Flex
          alignItems="center"
          cursor="pointer"
          onClick={() => setIsExpanded(!isExpanded)}
          p={6}
        >
          <Flex flex={1}>
            <Heading size="md" flex={1}>
              {`${index + 1}. ${task.title}`}
            </Heading>
          </Flex>
          <IconButton
            aria-label={isExpanded ? 'Hide' : 'Expand'}
            icon={
              isExpanded ? (
                <MinusIcon color="darkGray.900" />
              ) : (
                <AddIcon color="darkGray.900" />
              )
            }
            isRound
            size="xs"
            bg={`${quiz.color}.200`}
            _hover={{ bg: `${quiz.color}.100` }}
            _active={{ bg: `${quiz.color}.200` }}
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </Flex>
        <Collapse in={isExpanded} animateOpacity>
          <Box p={6} pt={0}>
            <Box
              mb={5}
              listStylePosition="inside"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(marked.parse(task.description)),
              }}
            />
            <Text fontWeight="bold" mb={2}>
              Great for your:
            </Text>
            <Flex ml={-2} mb={-4}>
              <BucketItem
                label={`${bucket.label} ${quiz.name}`}
                color={`${quiz.color}.200`}
              />
            </Flex>
          </Box>
        </Collapse>
      </Box>
    </>
  );
};

const MyTasks: React.FC<Props> = ({
  quizzes,
  traits,
  identities,
  buckets,
  tasks,
  match: { params },
  history,
}) => {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [userResults, setUserResults] = useState([] as ComputedResult[]);
  const [expandedTest, setExpandedTest] = useState(null as string | null);
  const [currentMonth, setCurrentMonth] = useState(
    dayjs().format(MONTH_FORMAT)
  );
  const [isLoading, setIsLoading] = useState(true);

  const user = useSelector((state) => state.user);

  const theme = useTheme();

  const quizzesArr = Object.values(quizzes) as Quiz[];
  const { id } = params;

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return;
      await dispatch(retrieveUser(id));
    };

    fetchData().then(async () => {
      await sleep(500);
      setIsLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!user) return;

    setUserResults(user.results);
    setName(user.name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(user)]);


  const identityResults = userResults.filter(
    (ur) =>
      ur.slug === 'eating-habits' ||
      ur.slug === 'food-mindset' ||
      ur.slug === 'environment' ||
      ur.slug === 'cooking-style'
  );

  const identity = Object.values(identities).find((identity) => {
    let isThisIdentity = true;
    identityResults.forEach((ir) => {
      const quiz = quizzesArr.find((q) => q.slug === ir.slug);
      const trait = traits[ir.traitId as string];

      // Run through identity buckets to disqualify identities that don't
      // match the user's trait results
      identity?.buckets?.map((idBucket) => {
        const isThisQuiz = idBucket.quiz === quiz?.id;
        const containsThisCategory = trait?.bucketCombination.includes(
          idBucket.category
        );
        if (isThisQuiz && !containsThisCategory) {
          isThisIdentity = false;
        }
        return isThisIdentity;
      });
    });
    return isThisIdentity;
  });

  let bucketIds: string[] = [];
  userResults.map((ur) => {
    const trait = traits[ur.traitId as string];
    trait?.bucketCombination.map((traitBucketCategory) => {
      const traitBucket = Object.values(buckets).find(
        (bucket) =>
          bucket?.category === traitBucketCategory &&
          bucket?.quiz === trait.quiz
      );
      if (traitBucket) {
        bucketIds = [...bucketIds, traitBucket.id];
      }
    });
  });

  const myTasks = Object.values(tasks).filter((task) => {
    // Check if task is attributed to one of the user's qualities (i.e. bucket results)
    if (task && bucketIds.includes(task.bucket)) {
      // Check that the task applies to the currently selected month
      return dayjs(task.releaseDate).format(MONTH_FORMAT) === currentMonth;
    }
    return false;
  });

  if (!identity || isLoading) return <Loading />;

  return (
    <Box minH="100vh" bg={theme.colors['brown']['50']}>
      <Box position="relative" bg={`${identity.category?.color}.200`}>
        <Box
          position="absolute"
          bottom={0}
          left={0}
          right={0}
          height="40vw"
          maxHeight="50%"
        >
          <CurvedSectionBreak color={theme.colors['brown']['50']} />
        </Box>
        <Container maxW="3xl" textAlign="center" py={12}>
          {id && (
            <Flex justifyContent="center" pb={8}>
              <Button
                variant="outline"
                onClick={() =>
                  history.push(navRoutes.public.foodId.path(id))
                }
              >
                See My Results
              </Button>
            </Flex>
          )}
          <Heading size="lg" pb={2}>
            {`${name}${
              name.charAt(name.length - 1) === 's' ? "'" : "'s"
            } Challenges For`}
          </Heading>
          <Heading size="3xl" pb={8}>
            {dayjs(currentMonth).format(DISPLAY_DATE_FORMAT)}
          </Heading>
          <Text mb={16} fontWeight="medium">
            Here are our personal recommendations to help you enjoy your food
            and build good habits this month.
          </Text>
          <Stack direction="column" spacing={3} w="100%">
            {myTasks.map((task, index) => {
              const bucket = task ? buckets[task.bucket] : null;
              const quiz = bucket ? quizzes[bucket.quiz] : null;
              if (!task || !bucket || !quiz) return null;
              return (
                <TaskItem
                  index={index}
                  task={task}
                  isExpanded={expandedTest === task.id}
                  setIsExpanded={(e) => {
                    if (!e) {
                      setExpandedTest(null);
                    } else {
                      setExpandedTest(task.id);
                    }
                  }}
                  color={identity.category.color}
                  bucket={bucket}
                  quiz={quiz}
                />
              );
            })}
          </Stack>
          <Stack direction={['column', 'row']} pt={8}>
            {dayjs(currentMonth).diff(dayjs(LAUNCH_DATE)) > 0 && (
              <Button
                variant="outline"
                leftIcon={<ArrowBackIcon />}
                onClick={() =>
                  setCurrentMonth(
                    dayjs(currentMonth)
                      .subtract(1, 'month')
                      .format(MONTH_FORMAT)
                  )
                }
              >
                {`Challenges for ${dayjs(currentMonth)
                  .subtract(1, 'month')
                  .format("MMM [']YY")}`}
              </Button>
            )}
            <Box flex={1} />
            {dayjs(currentMonth).diff(dayjs().startOf('month')) < 0 && (
              <Button
                rightIcon={<ArrowForwardIcon />}
                onClick={() =>
                  setCurrentMonth(
                    dayjs(currentMonth).add(1, 'month').format(MONTH_FORMAT)
                  )
                }
              >
                {`Challenges for ${dayjs(currentMonth)
                  .add(1, 'month')
                  .format("MMM [']YY")}`}
              </Button>
            )}
          </Stack>

          <Image
            position="relative"
            src={identity.imageUrl}
            alt={identity.name}
            w={['80%', '50%']}
            maxWidth="500px"
            maxHeight="500px"
            mx="auto"
            mt={32}
          />
        </Container>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    quizzes: state.quizzes.entities,
    traits: state.traits.entities,
    identities: state.identities.entities,
    buckets: state.buckets.entities,
    tasks: state.tasks.entities,
  };
};

const connector = connect(mapStateToProps);

export default connector(MyTasks);
