// --- Uncomment the desired environment --- //
// export const ENV = 'local';
// export const ENV = 'dev';
export const ENV = 'prod';

// --- This is the root file for all API calls --- //

export const urlMap = {
  // URL for local development
  local: {
    baseUrl: 'http://localhost:1337',
  },
  // URL for development
  dev: {
    baseUrl: 'https://xxx',
  },
  // URL for production
  prod: {
    baseUrl: 'https://food-id-backend.herokuapp.com/',
  },
};

// Default API prefix
// @ts-ignore
export const baseUrl = urlMap[ENV].baseUrl;
export const api = baseUrl;
