import {
  createSlice,
  Action,
  AnyAction,
  PayloadAction,
} from '@reduxjs/toolkit';

interface AppState {
  foodId?: string;
  onboardingCompleted?: boolean;
  quizzesCompleted?: boolean;
  show404?: boolean;
}

const feelings = localStorage.getItem('feelings');
const foodId = localStorage.getItem('food_id');
const goals = localStorage.getItem('goals');
const name = localStorage.getItem('name');
// const numPeople = localStorage.getItem('numPeople');

const onboardingCompleted = Boolean(name && goals && feelings);

const initialState = {
  foodId,
  onboardingCompleted,
  quizzesCompleted: false,
  show404: false,
} as AppState;

interface RejectedAction extends Action {
  error: Error;
}

const isRejectedAction = (action: AnyAction): action is RejectedAction =>
  action.type.endsWith('rejected');

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    completeOnboarding(state) {
      state.onboardingCompleted = true;
    },
    setQuizzesCompleted(state) {
      state.quizzesCompleted = true;
    },
    hide404(state) {
      state.show404 = false;
    },
    setFoodId(state, action: PayloadAction<string>) {
      state.foodId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isRejectedAction, (state, action) => {
      if (action.error.message.includes('404')) {
        state.show404 = true;
      }
    });
  },
});

export const { completeOnboarding, hide404, setFoodId, setQuizzesCompleted } =
  appSlice.actions;
export const appReducer = appSlice.reducer;

export default appSlice.reducer;
