import { HomeScreen } from 'screens/global/Home';
import { AboutMeScreen } from 'screens/personal/AboutMe';
import { MyTestsScreen } from 'screens/personal/MyTests';
import { EmailResultsScreen } from 'screens/personal/EmailResults';
import { MyFoodIdScreen } from 'screens/personal/MyFoodId';
import { MyTasksScreen } from 'screens/personal/MyTasks';
import { QuizScreen } from 'screens/personal/Quiz';

export interface Route {
  /** The component/screen to render */
  component: any;
  /** Name to display in the breadcrumbs */
  name: string;
  /** The route path */
  path: (...arg: any[]) => string;
  /** Set to true to show 404 page when relevant (i.e when retrieving data fails) */
  show404?: boolean;
}

const routes = {
  // Routes that are accessible regardless of current user journey
  public: {
    challenges: {
      path: (id = ':id') => `/food-id/${id}/challenges`,
      name: 'Challenges',
      component: MyTasksScreen,
      show404: true,
    },
    foodId: {
      path: (id = ':id') => `/food-id/${id}`,
      name: 'Food ID',
      component: MyFoodIdScreen,
      show404: true,
    },
  },
  // Onboarding journey - routes used to gather data before getting started with the quizzes
  onboarding: {
    home: {
      path: () => '/welcome',
      name: 'Home',
      component: HomeScreen,
    },
    aboutMe: {
      path: () => '/about-me',
      name: 'About Me',
      component: AboutMeScreen,
    },
  },
  // Routes that are accessible to users that have completed the onboarding
  quizzes: {
    myTests: {
      path: () => '/my-tests',
      name: 'My Tests',
      component: MyTestsScreen,
    },
    quiz: {
      path: (quizSlug = ':quizSlug') => `/test/${quizSlug}`,
      name: 'Test',
      component: QuizScreen,
    },
  },
  // Routes that are only accessible to users that have completed all quizzes
  quizzesCompleted: {
    emailResults: {
      path: () => '/email-results',
      name: 'Email My Results',
      component: EmailResultsScreen,
    },
  },
} as const;

export type Routes = typeof routes;

export default routes;
