import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { AddIcon, ArrowForwardIcon, MinusIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Collapse,
  Container,
  Flex,
  Heading,
  IconButton,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';

import navRoutes from 'navigation/Routes';
import { RootState } from 'redux/store';
import { useDispatch } from 'redux/hooks';
import { setQuizzesCompleted } from 'redux/slices/app';
import { Bucket, ComputedResult, Quiz, Trait } from 'types';
import { minifyUserResults, useQuizzesProgress } from 'utils';

import { BucketList } from 'components/BucketList';

import arrow1 from 'assets/svg/arrows/s-arrow-167.svg';
import squiggle3 from 'assets/svg/squiggles/s-squiggle-109.svg';
import squiggle2 from 'assets/svg/squiggles/s-squiggle-127.svg';
import squiggle4 from 'assets/svg/squiggles/s-squiggle-141.svg';
import squiggle1 from 'assets/svg/squiggles/s-squiggle-97.svg';

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux, RouteComponentProps {}

const CompleteTest: React.FC<{
  result: ComputedResult;
  isExpanded: boolean;
  setIsExpanded: (e: boolean) => void;
  quiz?: Quiz;
  trait?: Trait;
  allBuckets: Bucket[];
}> = ({ result, isExpanded, setIsExpanded, quiz, trait, allBuckets }) => {
  if (!quiz) return null;
  return (
    <>
      <Box
        bg={`${quiz.color}.200`}
        p={[4, 8]}
        pt={[4, 4]}
        pb={[1, 1]}
        borderTopRadius={10}
        textAlign="left"
        w="95%"
        ml="auto"
        mr="auto"
        zIndex={0}
      >
        <Flex
          alignItems="center"
          cursor="pointer"
          onClick={() => setIsExpanded(!isExpanded)}
          pb={3}
        >
          <Flex flex={1} flexDirection={['column', 'row']}>
            <Heading fontWeight="normal" size="sm" pr={1}>
              {isExpanded ? quiz.name : `${quiz.name}:`}
            </Heading>
            <Heading size="sm" flex={1}>
              {isExpanded ? null : trait?.name}
            </Heading>
          </Flex>
          <IconButton
            aria-label={isExpanded ? 'Hide' : 'Expand'}
            icon={isExpanded ? <MinusIcon /> : <AddIcon />}
            isRound
            size="xs"
            onClick={() => setIsExpanded(!isExpanded)}
            variant="outline"
          />
        </Flex>
        <Collapse in={isExpanded} animateOpacity>
          <Heading mb={4}>{trait?.name}</Heading>
          <Flex ml={-2} mb={4}>
            <BucketList results={[result]} />
          </Flex>
          <Text>{trait?.summary}</Text>
          <Stack
            direction={['column', 'row']}
            alignItems={['flex-start', 'center']}
            spacing={4}
            mt={6}
            mb={5}
          >
            <Text fontWeight="semibold" flex={1} pr={5}>
              Complete all tests for details and recommendations
            </Text>
            <Button
              as={Link}
              to={navRoutes.quizzes.quiz.path(quiz.slug)}
              rightIcon={<ArrowForwardIcon />}
            >
              Retake Test
            </Button>
          </Stack>
        </Collapse>
      </Box>
      <Flex position="relative" justifyContent="center">
        <Box
          position="absolute"
          top={0}
          w="95%"
          height={6}
          bg={`${quiz.color}.200`}
          zIndex={-1}
        />
      </Flex>
    </>
  );
};

const MyTests: React.FC<Props> = ({ history, quizzes, traits, buckets }) => {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [friends, setFriends] = useState([]);
  const [expandedTest, setExpandedTest] = useState(null as string | null);

  const {
    quizzesCompleted,
    completeResults,
    numQuizzes,
    numUserResults,
    numRemainingQuizzes,
    remainingQuizzes,
    userResults,
  } = useQuizzesProgress();

  useEffect(() => {
    const name = localStorage.getItem('name');
    if (name) {
      setName(name);
    }

    const friendsStr = localStorage.getItem('friends');
    const friends = friendsStr ? JSON.parse(friendsStr) : [];
    setFriends(friends);
  }, []);

  useEffect(() => {
    if (quizzesCompleted) {
      dispatch(setQuizzesCompleted());
    }
  }, [quizzesCompleted, dispatch]);

  return (
    <>
      <Container maxW="2xl" overflow={['hidden', 'hidden', 'visible']}>
        <Stack as={Box} spacing={{ base: 8, md: 14 }} py={{ base: 20, md: 36 }}>
          {numRemainingQuizzes > 0 && (
            <Box>
              <Text fontSize="2xl" mb={4}>
                Take the following tests to discover your cooking personality.
              </Text>
              <Text fontSize="2xl" fontWeight="semibold">
                {numRemainingQuizzes === numQuizzes
                  ? 'This will take just 5 mins...'
                  : `Just ${numRemainingQuizzes} more to go!`}
              </Text>
            </Box>
          )}
          <Stack
            direction="column"
            spacing={6}
            align="center"
            alignSelf="center"
            position="relative"
            w="100%"
          >
            <Box w="100%">
              {completeResults.map((result) => {
                const { traitId, tally, slug } = result;
                if (!traitId) return null;
                return (
                  <CompleteTest
                    key={`${slug}__${traitId}__${tally}`}
                    quiz={quizzes[slug]}
                    trait={traits[traitId]}
                    result={result}
                    allBuckets={Object.values(buckets) as Bucket[]}
                    isExpanded={expandedTest === slug}
                    setIsExpanded={(e) => {
                      if (!e) {
                        setExpandedTest(null);
                      } else {
                        setExpandedTest(slug);
                      }
                    }}
                  />
                );
              })}
              <Box
                bg="white"
                borderWidth={2}
                borderColor="black"
                p={[10, 12]}
                borderRadius={10}
                textAlign="left"
                w="100%"
                zIndex={1}
              >
                <Flex alignItems="center">
                  <Box>
                    <Heading>{name}</Heading>
                    <Text fontSize="2xl" fontWeight="medium">{`Cooking for ${
                      friends.length + 1
                    }`}</Text>
                  </Box>
                </Flex>
                {quizzesCompleted ? (
                  <>
                    <Stack
                      direction={['column', 'row']}
                      alignItems={['flex-start', 'center']}
                      spacing={4}
                      mt={10}
                    >
                      <Text fontWeight="semibold" flex={1}>
                        All Tests Complete
                      </Text>
                      <Box position="relative">
                        <Image
                          src={arrow1}
                          position="absolute"
                          bottom={[-145, -145, 10]}
                          right={[70, 70, -260]}
                          width={['200px', '200px', '300px']}
                          maxWidth="500px"
                          pointerEvents="none"
                          transform={[
                            'scaleX(-1)',
                            'scaleX(-1)',
                            'rotate(270deg)',
                          ]}
                          zIndex={1}
                        />
                        <Button
                          onClick={() => {
                            if (traits) {
                              const userResultsId = minifyUserResults(
                                userResults,
                                Object.values(traits) as Trait[]
                              );
                              localStorage.setItem(
                                'userResultsId',
                                userResultsId
                              );
                              localStorage.setItem(
                                'userResults',
                                JSON.stringify(userResults)
                              );
                            }

                            history.push(
                              navRoutes.quizzesCompleted.emailResults.path()
                            );
                          }}
                          colorScheme="brand"
                          rightIcon={<ArrowForwardIcon />}
                          size="lg"
                        >
                          Get My Results
                        </Button>
                      </Box>
                    </Stack>
                    <Flex h={2} borderRadius={10} overflow="hidden" mt={4}>
                      <Flex flex={1} bg="green.400" />
                    </Flex>
                    <Box position="relative">
                      <Image
                        src={squiggle4}
                        position="absolute"
                        bottom={-200}
                        left={-120}
                        width="100px"
                        transform="rotate(330deg)"
                        zIndex={1}
                        opacity={[0, 0, 1]}
                        pointerEvents="none"
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Stack
                      direction={['column', 'row']}
                      alignItems={['flex-start', 'center']}
                      spacing={4}
                      mt={10}
                    >
                      <Text flex={1}>
                        {`Tests Completed: `}
                        <Text as="b">{`${numUserResults} of ${numQuizzes}`}</Text>
                      </Text>
                      <Button
                        as={Link}
                        to={navRoutes.quizzes.quiz.path(
                          remainingQuizzes.filter((q) => !!q.slug)[0]?.slug
                        )}
                        rightIcon={<ArrowForwardIcon />}
                      >
                        Take Next Test
                      </Button>
                    </Stack>
                    <Flex h={3} borderRadius={10} overflow="hidden" mt={4}>
                      <Flex flex={numUserResults} bg="green.200" />
                      <Flex flex={numQuizzes - numUserResults} bg="brown.50" />
                    </Flex>
                  </>
                )}
              </Box>
            </Box>
            {remainingQuizzes.map((quiz, index) => (
              <Box w="100%" position="relative">
                <Box
                  bg={`${quiz.color}.200`}
                  borderRadius={10}
                  textAlign="left"
                  w="100%"
                  overflow="hidden"
                >
                  <Box p={[10, 12]}>
                    <Heading variant="script" fontSize="5xl" mb={4}>
                      {quiz.name}
                    </Heading>
                    <Text fontWeight="medium">{quiz.description}</Text>
                    <Flex alignItems="center" mt={6}>
                      <Text flex={1}>
                        <Text as="b">{quiz.questions.length}</Text>
                        {` Questions`}
                      </Text>
                      <Button
                        as={Link}
                        to={navRoutes.quizzes.quiz.path(quiz.slug)}
                        rightIcon={<ArrowForwardIcon />}
                        variant="outline"
                      >
                        Take Test
                      </Button>
                    </Flex>
                  </Box>
                </Box>
                {index === 0 ? (
                  <Image
                    src={squiggle1}
                    position="absolute"
                    top={[65, 65, 10]}
                    left={[-45, -45, -90]}
                    width={['80px', '80px', '120px']}
                    maxWidth="500px"
                    pointerEvents="none"
                    zIndex={1}
                  />
                ) : index === 2 ? (
                  <Image
                    src={squiggle2}
                    position="absolute"
                    top={[200, 200, 150]}
                    right={[-130, -130, -180]}
                    width={['250px', '250px', '300px']}
                    maxWidth="500px"
                    transform="rotate(90deg)"
                    pointerEvents="none"
                    zIndex={1}
                  />
                ) : index === 4 ? (
                  <Image
                    src={squiggle3}
                    position="absolute"
                    top={[40, 40, 20]}
                    left={[-35, -35, -65]}
                    width={['80px', '80px', '100px']}
                    maxWidth="500px"
                    pointerEvents="none"
                    zIndex={1}
                  />
                ) : null}
              </Box>
            ))}
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    quizzes: state.quizzes.entities,
    traits: state.traits.entities,
    buckets: state.buckets.entities,
  };
};

const connector = connect(mapStateToProps);

export default connector(MyTests);
