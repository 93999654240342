import {
  TypedUseSelectorHook,
  useDispatch as baseUseDispatch,
  useSelector as baseUseSelector,
} from 'react-redux';
import type { RootState, AppDispatch } from './store';

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useDispatch = () => baseUseDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = baseUseSelector;
