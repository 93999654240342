import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import { api } from 'constants/env';

export const http = applyCaseMiddleware(
  axios.create({
    baseURL: api,
    headers: {
      'Content-type': 'application/json',
    },
  }),
  { preservedKeys: ['XA', 'XB', 'YA', 'YB', '_1', '_2', '_3'] }
);
