import { AxiosResponse } from 'axios';
import {
  createSlice,
  createAsyncThunk,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

import { urls } from 'constants/api';
import { http } from 'utils';
import { User } from 'types';

export const retrieveUser = createAsyncThunk(
  `user/retrieve`,
  async (uid: string) => {
    const response = await http.get<any, AxiosResponse<User>>(urls.user(uid));
    return response.data;
  }
);

type UserState = User | null;

export const userSlice = createSlice<UserState, SliceCaseReducers<UserState>>({
  name: 'user',
  initialState: null,
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(retrieveUser.fulfilled, (state, action) => action.payload),
});

export const userReducer = userSlice.reducer;
