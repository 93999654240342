import React, { useEffect, useRef, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { RootState } from 'redux/store';

import { ArrowDownIcon, ArrowForwardIcon, ArrowUpIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  ScaleFade,
  Stack,
  Text,
} from '@chakra-ui/react';

import navRoutes from 'navigation/Routes';
import type { AnswerOptions, Bucket, QuizQuestion } from 'types';
import { ComputedResult, Trait } from 'types';
import { getTrait } from 'utils';

import { BucketList } from 'components/BucketList';
import Loading from 'components/Loading';

import arrow1 from 'assets/svg/arrows/s-arrow-99.svg';
import squiggle2 from 'assets/svg/squiggles/s-squiggle-128.svg';
import squiggle1 from 'assets/svg/squiggles/s-squiggle-131.svg';

type PropsFromRedux = ConnectedProps<typeof connector>;

// Routing Props
interface MatchParams {
  quizSlug: string;
}

interface OwnProps extends RouteComponentProps<MatchParams> {}

interface Props extends OwnProps, PropsFromRedux {}

const timeout = async (delay: number) => {
  return new Promise((res) => setTimeout(res, delay));
};

var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const scrollBehaviour = isSafari ? true : ({ behavior: 'smooth' } as const);

const Question: React.FC<{
  question: QuizQuestion;
  isActive: boolean;
  nextQuestion: (answer: AnswerOptions, bucket: number, points: number) => void;
  prevQuestion: () => void;
  selectedAnswer: AnswerOptions;
}> = ({ question, isActive, nextQuestion, prevQuestion, selectedAnswer }) => {
  const qRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isActive && qRef.current) {
      qRef.current?.scrollIntoView(scrollBehaviour);
    }
  }, [isActive]);

  const vHeight = use100vh();
  const screenHeight = vHeight ? `${vHeight}px` : '100vh';

  return (
    <Stack
      ref={qRef}
      spacing={5}
      minHeight={screenHeight}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      <Box mb={5}>
        <Text color="white">How much do you agree with the following?</Text>
        <Heading fontSize={['xl', '2xl', '3xl']} color="white">
          {question.statement}
        </Heading>
      </Box>
      <Stack spacing={[3, 4, 5]} alignItems="center" width={350} maxWidth="90%">
        <Button
          colorScheme="green"
          onClick={() => nextQuestion('sa', question.agreeBucket, 5)}
          variant={selectedAnswer === 'sa' ? 'solid' : 'outline'}
          w="100%"
        >
          Strongly Agree
        </Button>
        <Button
          colorScheme="green"
          onClick={() => nextQuestion('a', question.agreeBucket, 3)}
          variant={selectedAnswer === 'a' ? 'solid' : 'outline'}
          w="80%"
        >
          Agree
        </Button>
        <Button
          colorScheme="lightGray"
          onClick={() => nextQuestion('n', question.agreeBucket, 1)}
          variant={selectedAnswer === 'n' ? 'solid' : 'outline'}
          w="60%"
        >
          Neutral
        </Button>
        <Button
          colorScheme="red"
          onClick={() => nextQuestion('d', question.disagreeBucket, 3)}
          variant={selectedAnswer === 'd' ? 'solid' : 'outline'}
          w="80%"
        >
          Disagree
        </Button>
        <Button
          colorScheme="red"
          onClick={() => nextQuestion('sd', question.disagreeBucket, 5)}
          variant={selectedAnswer === 'sd' ? 'solid' : 'outline'}
          w="100%"
        >
          Strongly Disagree
        </Button>
      </Stack>
      <Box textAlign="right" pt={[6, 8, 10]}>
        <Button
          variant="outline"
          colorScheme="whiteAlpha"
          onClick={prevQuestion}
          rightIcon={<ArrowUpIcon />}
        >
          Back
        </Button>
      </Box>
    </Stack>
  );
};

const Quiz: React.FC<Props> = ({
  history,
  quiz,
  questions,
  buckets,
  traits,
}) => {
  const topRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState(
    [] as {
      question: QuizQuestion;
      answer: AnswerOptions;
      bucket: number;
      points: number;
    }[]
  );
  const [trait, setTrait] = useState({} as Trait);
  const [result, setResult] = useState({} as ComputedResult);
  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (currentQuestion === 0) {
      topRef.current?.scrollIntoView(scrollBehaviour);
    } else if (quiz && currentQuestion === quiz.questions.length + 1) {
      bottomRef.current?.scrollIntoView(scrollBehaviour);
    }
  }, [currentQuestion]);

  const vHeight = use100vh();
  const screenHeight = vHeight ? `${vHeight}px` : '100vh';

  if (!quiz) return <Loading />;

  const numAnswers = answers.filter((a) => a?.answer).length;

  return (
    <>
      <Box height={screenHeight} overflow="hidden">
        <Flex
          ref={topRef}
          bg={`${quiz.color}.200`}
          minHeight={screenHeight}
          flexDirection="column"
          overflow="hidden"
        >
          <Flex
            position="relative"
            flexDirection="column"
            flex={1}
            justifyContent="center"
          >
            <Container maxW="3xl">
              <Stack
                as={Box}
                textAlign="center"
                spacing={{ base: 8, md: 14 }}
                py={{ base: 20, md: 36 }}
                justifyContent="center"
              >
                <Heading variant="script" fontSize="6xl">
                  {quiz.name}
                </Heading>
                <Text fontWeight="medium">{quiz.description}</Text>
                <Stack
                  direction="column"
                  spacing={5}
                  align="center"
                  alignSelf="center"
                  position="relative"
                >
                  <Text>
                    <Text as="b">{quiz.questions.length}</Text>
                    {` Questions`}
                  </Text>
                  <Button
                    rightIcon={<ArrowDownIcon />}
                    onClick={() => setCurrentQuestion(1)}
                  >
                    Start Test
                  </Button>
                </Stack>
              </Stack>
            </Container>
          </Flex>
        </Flex>
        <Box bg="gray.900">
          <Container maxW="3xl">
            {quiz.questions.map((id, index) => {
              const question = questions[id];
              if (!question) return null;
              return (
                <Question
                  question={question}
                  isActive={currentQuestion === index + 1}
                  nextQuestion={async (answer, bucket, points) => {
                    const newAnswers = [...answers];
                    newAnswers[currentQuestion] = {
                      question,
                      answer,
                      bucket,
                      points,
                    };
                    setAnswers(newAnswers);
                    await timeout(500);
                    setCurrentQuestion(currentQuestion + 1);
                  }}
                  prevQuestion={() => setCurrentQuestion(currentQuestion - 1)}
                  selectedAnswer={
                    answers[index + 1] ? answers[index + 1].answer : null
                  }
                />
              );
            })}
          </Container>
        </Box>
        <Box
          bg={
            showResults
              ? `${quiz.color}.200`
              : numAnswers === quiz.questions.length
              ? 'green.200'
              : `${quiz.color}.200`
          }
        >
          <Container maxW="lg">
            <Stack
              ref={bottomRef}
              as={Box}
              textAlign="center"
              py={[10, 20, 30]}
              height={screenHeight}
              justifyContent="center"
              overflowY="scroll"
            >
              <Box maxHeight="100%">
                {showResults ? (
                  <ScaleFade initialScale={0.9} in={showResults}>
                    <Stack
                      direction="column"
                      spacing={[4, 6]}
                      align="center"
                      alignSelf="center"
                      position="relative"
                      pb={20}
                    >
                      <Box>
                        <Text>Your result is...</Text>
                        <Heading fontSize={['2xl', '3xl']}>
                          {trait.name}
                        </Heading>
                        <Flex justifyContent="center" mt={[3, 4]}>
                          <BucketList results={[result]} />
                        </Flex>
                      </Box>
                      <Text>{trait.summary}</Text>
                      <Text fontWeight="bold" flex={1}>
                        Finish up your tests to meet your Sidekick character and
                        receive personalised challenges.
                      </Text>
                      <Box position="relative">
                        <Image
                          src={arrow1}
                          position="absolute"
                          top={[-25, -25]}
                          right={[-120, -120]}
                          width={['150px', '150px']}
                          maxWidth="500px"
                          transform="rotate(270deg) scaleY(-1)"
                          pointerEvents="none"
                          zIndex={1}
                        />
                        <Button
                          onClick={() =>
                            history.push(navRoutes.quizzes.myTests.path())
                          }
                          rightIcon={<ArrowForwardIcon />}
                          size="lg"
                        >
                          Continue
                        </Button>
                      </Box>
                    </Stack>
                  </ScaleFade>
                ) : (
                  <Stack
                    direction="column"
                    spacing={5}
                    align="center"
                    alignSelf="center"
                    position="relative"
                  >
                    {numAnswers === quiz.questions.length ? (
                      <Button
                        rightIcon={<ArrowForwardIcon />}
                        onClick={async () => {
                          setIsLoading(true);
                          // Run through answers, tally up buckets, save results to local storage
                          const tally = {
                            XA: 0,
                            XB: 0,
                            YA: 0,
                            YB: 0,
                            _1: 0,
                            _2: 0,
                            _3: 0,
                          };
                          answers
                            .filter((a) => a?.answer)
                            .forEach((a) => {
                              const bucket = buckets[a.bucket] as Bucket;
                              if (a) {
                                tally[bucket.category] =
                                  tally[bucket.category] + a.points;
                              }
                            });
                          const userResult = {
                            slug: quiz.slug,
                            answers,
                            tally,
                          };
                          localStorage.setItem(
                            `${quiz.slug}-result`,
                            JSON.stringify(userResult)
                          );
                          await timeout(1500);
                          const trait = getTrait(
                            quiz,
                            Object.values(traits) as Trait[],
                            userResult
                          );
                          if (trait) {
                            setTrait(trait);
                            setResult({
                              slug: quiz.slug,
                              tally,
                              traitId: trait.id,
                            });
                            setShowResults(true);
                          }
                          setIsLoading(false);
                        }}
                        isLoading={isLoading}
                        loadingText="Processing Result"
                        size="lg"
                      >
                        Complete Test
                      </Button>
                    ) : (
                      <Text fontWeight="semibold">
                        {`${quiz.questions.length - numAnswers} question${
                          quiz.questions.length - numAnswers === 1 ? '' : 's'
                        } remaining`}
                      </Text>
                    )}
                    <Button
                      onClick={() => setCurrentQuestion(currentQuestion - 1)}
                      rightIcon={<ArrowUpIcon />}
                      variant="outline"
                    >
                      Back
                    </Button>
                  </Stack>
                )}
              </Box>
            </Stack>
          </Container>
        </Box>
      </Box>
      <Box
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        pointerEvents="none"
      >
        <Image
          src={squiggle1}
          position="absolute"
          top={[0, 50]}
          right={[-0, 50]}
          width={['80px', '200px']}
          maxWidth="500px"
          transform="scaleX(-1)"
          pointerEvents="none"
          zIndex={1}
          filter="invert(1)"
        />
        <Image
          src={squiggle2}
          position="absolute"
          bottom={[10, 100]}
          left={[0, 0]}
          width={['100px', '200px']}
          maxWidth="500px"
          transform="rotate(90deg)"
          pointerEvents="none"
          zIndex={1}
          filter="invert(1)"
        />
      </Box>
      {!showResults && (
        <Box position="absolute" bottom={0} left={0} right={0} p={8}>
          <Stack justifyContent="center" direction="row" flex={1}>
            {quiz.questions.map((q, index) => (
              <Box
                h={3}
                w={3}
                borderRadius={10}
                borderWidth={1}
                borderColor={
                  index + 1 === currentQuestion ? 'yellow.200' : 'white'
                }
                background={
                  index + 1 === currentQuestion
                    ? 'yellow.200'
                    : answers[index + 1]?.answer
                    ? 'white'
                    : 'none'
                }
                _hover={{
                  background:
                    index + 1 === currentQuestion
                      ? 'yellow.200'
                      : answers[index + 1]?.answer
                      ? 'gray.100'
                      : 'gray.600',
                }}
                cursor="pointer"
                onClick={() => setCurrentQuestion(index + 1)}
              />
            ))}
          </Stack>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const {
    match: { params },
  } = ownProps;
  const { quizSlug } = params;
  return {
    quiz: state.quizzes.entities[quizSlug],
    questions: state.questions.entities,
    buckets: state.buckets.entities,
    traits: state.traits.entities,
  };
};

const connector = connect(mapStateToProps);

export default connector(Quiz);
