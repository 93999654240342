import React, { useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { Box, Container, Flex, Stack } from '@chakra-ui/react';

import { RootState } from 'redux/store';

import steps from './AboutMeSteps';

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux, RouteComponentProps {}

const AboutMe: React.FC<Props> = () => {
  const [name, setName] = useState('');
  const [happyWithFood, setHappyWithFood] = useState(false);
  const [step, setStep] = useState(0);

  const vHeight = use100vh();
  const screenHeight = vHeight ? `${vHeight}px` : '100vh';

  const CurrentStep = steps[step];

  return (
    <Flex bg="brown.50" minH={screenHeight} alignItems="center">
      <Container maxW="3xl">
        <Stack
          as={Box}
          textAlign="center"
          alignItems="center"
          spacing={{ base: 10 }}
          // py={{ base: 20, md: 36 }}
        >
          <CurrentStep
            setName={setName}
            name={name}
            onNext={() => setStep((prev) => prev + 1)}
            happyWithFood={happyWithFood}
            setHappyWithFood={setHappyWithFood}
          />
        </Stack>
      </Container>
    </Flex>
  );
};

const mapStateToProps = (state: RootState) => {
  return {};
};

const connector = connect(mapStateToProps);

export default connector(AboutMe);
