import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { http } from 'utils';
import { urls } from 'constants/api';

import {
  Approach,
  Bucket,
  FullCategory,
  Identity,
  Quiz,
  QuizQuestion,
  Task,
  Trait,
} from 'types';

type NormalizedResponse = {
  approaches: { [key: string]: Approach };
  categories: { [key: string]: FullCategory };
  buckets: { [key: string]: Bucket };
  identities: { [key: string]: Identity };
  questions: { [key: string]: QuizQuestion };
  quizzes: { [key: string]: Quiz };
  tasks: { [key: string]: Task };
  traits: { [key: string]: Trait };
};

export const quizTraitEntity = new schema.Entity(
  'traits',
  {},
  { processStrategy: (value, parent) => ({ ...value, quiz: parent.slug }) }
);

export const quizBucketEntity = new schema.Entity(
  'buckets',
  {},
  { processStrategy: (value, parent) => ({ ...value, quiz: parent.slug }) }
);

export const quizQuestionEntity = new schema.Entity(
  'questions',
  {},
  { processStrategy: (value, parent) => ({ ...value, quiz: parent.slug }) }
);

export const quizEntity = new schema.Entity(
  'quizzes',
  {
    questions: [quizQuestionEntity],
    buckets: [quizBucketEntity],
    traits: [quizTraitEntity],
  },
  { idAttribute: 'slug' }
);

export const fetchQuizzes = createAsyncThunk('quizzes/fetchAll', async () => {
  const response = await http.get(urls.quizzes);
  const normalized = normalize<any, NormalizedResponse>(response.data, [
    quizEntity,
  ]);
  return normalized.entities;
});

const isAnyOf =
  (...matchers: Array<string | { type: string }>) =>
  (action: any) =>
    matchers.some((matcher) =>
      typeof matcher === 'string'
        ? matcher === action.type
        : matcher.type === action.type
    );

/** -------------------- SLICES -------------------- */

/**  --------- APPROACHES --------- */

export const approachEntity = new schema.Entity('approaches', {});

export const fetchApproaches = createAsyncThunk(
  'approaches/fetchAll',
  async () => {
    const response = await http.get(urls.approaches);
    const normalized = normalize<any, NormalizedResponse>(response.data, [
      approachEntity,
    ]);
    return normalized.entities;
  }
);

const approachesAdapter = createEntityAdapter<Approach>();

const approachesInitialState = approachesAdapter.getInitialState();

const approachesSlice = createSlice({
  name: 'approaches',
  initialState: approachesInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchApproaches.fulfilled, (state, action) => {
      approachesAdapter.upsertMany(state, action.payload.approaches);
    });
  },
});

const approachesReducer = approachesSlice.reducer;

/**----------------------------  */

/** --------- BUCKETS --------- */

const bucketsAdapter = createEntityAdapter<Bucket>();

const bucketsInitialState = bucketsAdapter.getInitialState();

const bucketsSlice = createSlice({
  name: 'buckets',
  initialState: bucketsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchQuizzes.fulfilled, (state, action) => {
      bucketsAdapter.upsertMany(state, action.payload.buckets);
    });
  },
});

const bucketsReducer = bucketsSlice.reducer;

/**-------------------------- */

/** --------- CATEGORIES --------- */

export const categoriesEntity = new schema.Entity('categories', {});

export const fetchcategories = createAsyncThunk(
  'categories/fetchAll',
  async () => {
    const response = await http.get(urls.categories);
    const normalized = normalize<any, NormalizedResponse>(response.data, [
      categoriesEntity,
    ]);
    return normalized.entities;
  }
);

const categoriesAdapter = createEntityAdapter<FullCategory>();

const categoriesInitialState = categoriesAdapter.getInitialState();

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: categoriesInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchcategories.fulfilled, (state, action) => {
      categoriesAdapter.upsertMany(state, action.payload.categories);
    });
  },
});

const categoriesReducer = categoriesSlice.reducer;

/**------------------------------- */

/** --------- IDENTITIES --------- */

export const identityEntity = new schema.Entity('identities', {});

export const fetchIdentities = createAsyncThunk(
  'identities/fetchAll',
  async () => {
    const response = await http.get(urls.identities);
    const normalized = normalize<any, NormalizedResponse>(response.data, [
      identityEntity,
    ]);
    return normalized.entities;
  }
);

const identitiesAdapter = createEntityAdapter<Identity>();

const identitiesInitialState = identitiesAdapter.getInitialState();

const identitiesSlice = createSlice({
  name: 'identities',
  initialState: identitiesInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchIdentities.fulfilled, (state, action) => {
      identitiesAdapter.upsertMany(state, action.payload.identities);
    });
  },
});

const identitiesReducer = identitiesSlice.reducer;

/**------------------------------ */

/** --------- QUESTIONS --------- */

const questionsAdapter = createEntityAdapter<QuizQuestion>();

const questionsInitialState = questionsAdapter.getInitialState();

const questionsSlice = createSlice({
  name: 'questions',
  initialState: questionsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchQuizzes.fulfilled, (state, action) => {
      questionsAdapter.upsertMany(state, action.payload.questions);
    });
  },
});

const questionsReducer = questionsSlice.reducer;

/**---------------------------- */

/** --------- QUIZZES --------- */

const quizzesAdapter = createEntityAdapter<Quiz>({
  selectId: (quiz) => quiz.slug,
});

const quizzesInitialState = quizzesAdapter.getInitialState();

const quizzesSlice = createSlice({
  name: 'quizzes',
  initialState: quizzesInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchQuizzes.fulfilled, (state, action) => {
      quizzesAdapter.upsertMany(state, action.payload.quizzes);
    });
  },
});

const quizzesReducer = quizzesSlice.reducer;

/**-------------------------- */

/** --------- TASKS --------- */

export const taskEntity = new schema.Entity(
  'tasks',
  {},
  { processStrategy: (value) => ({ ...value, bucket: value.bucket.id }) }
);

export const fetchTasks = createAsyncThunk('tasks/fetchAll', async () => {
  const response = await http.get(urls.tasks);
  const normalized = normalize<any, NormalizedResponse>(response.data, [
    taskEntity,
  ]);
  return normalized.entities;
});

const tasksAdapter = createEntityAdapter<Task>();

const tasksInitialState = tasksAdapter.getInitialState();

const tasksSlice = createSlice({
  name: 'tasks',
  initialState: tasksInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTasks.fulfilled, (state, action) => {
      tasksAdapter.upsertMany(state, action.payload.tasks);
    });
  },
});

const tasksReducer = tasksSlice.reducer;

/**-------------------------- */

/** --------- TRAITS --------- */

export const traitEntity = new schema.Entity(
  'traits',
  {},
  {
    processStrategy: (value, parent) => ({
      ...value,
      quiz: value.quiz.slug,
      // Category used here for convenience – every trait within
      // a quiz will have a unique combination of bucket categories,
      // and other bucket details can be inferred from context
      bucketCombination: value.bucketCombination.map(
        (bucket: Bucket) => bucket.category
      ),
    }),
  }
);

export const fetchTraits = createAsyncThunk('traits/fetchAll', async () => {
  const response = await http.get(urls.traits);
  const normalized = normalize<any, NormalizedResponse>(response.data, [
    traitEntity,
  ]);
  return normalized.entities;
});

const traitsAdapter = createEntityAdapter<Trait>();

const traitsInitialState = traitsAdapter.getInitialState();

const traitsSlice = createSlice({
  name: 'traits',
  initialState: traitsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(fetchQuizzes.fulfilled, fetchTraits.fulfilled),
      (state, action) => {
        traitsAdapter.upsertMany(state, action.payload.traits);
      }
    );
  },
});

const traitsReducer = traitsSlice.reducer;

export {
  approachesReducer,
  bucketsReducer,
  categoriesReducer,
  identitiesReducer,
  questionsReducer,
  quizzesReducer,
  tasksReducer,
  traitsReducer,
};
