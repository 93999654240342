import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { use100vh } from 'react-div-100vh';
import Lottie from 'lottie-react';

import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
  Flex,
  Image,
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

import navRoutes from 'navigation/Routes';

import { RootState } from 'redux/store';

import animatedLogo from 'assets/animation/food_id_logo.json';
import squiggle1 from 'assets/svg/squiggles/s-squiggle-136.svg';

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux, RouteComponentProps {}

const Home: React.FC<Props> = ({ history }) => {
  const vHeight = use100vh();
  const screenHeight = vHeight ? `${vHeight}px` : '100vh';
  return (
    <Flex bg="brown.50" minH={screenHeight} alignItems="center">
      <Container maxW="3xl">
        {/* <Lottie
          animationData={animatedLogo}
          onComplete={() => console.log('END')}
        /> */}
        <Stack
          as={Box}
          textAlign="center"
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
        >
          <Box position="relative">
            <Image
              src={squiggle1}
              position="absolute"
              width={[100, 120, 200]}
              top={[-55, -50, -150]}
              left={[-30, -25, -100]}
            />
            <Heading
              as="h1"
              fontWeight={600}
              fontSize={['3xl', '5xl']}
              lineHeight="160%"
            >
              {`Discover your cooking`}
              <br></br>
              <Text as="span" variant="script" fontSize={['5xl', '8xl']}>
                Personality
              </Text>
            </Heading>
          </Box>
          <Text fontSize={['xl', '2xl']}>
            Learn more about your relationship with the food you cook and eat.
            <br />
            Unlock brilliant and personalised monthly challenges designed to
            enhance your lifestyle.
          </Text>
          <Stack
            direction="column"
            spacing={3}
            align="center"
            alignSelf="center"
            position="relative"
          >
            <Button
              rightIcon={<ArrowForwardIcon />}
              size="lg"
              colorScheme="yellow"
              as={Link}
              to={navRoutes.onboarding.aboutMe.path()}
            >
              Let's Go!
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
};

const mapStateToProps = (state: RootState) => {
  return {};
};

const connector = connect(mapStateToProps);

export default connector(Home);
