export const CurvedSectionBreak = ({ color }: { color?: string }): JSX.Element => {
  return (
    <svg
      viewBox="0 0 500 200"
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M 502.084 223.146 L -2.857 223.146 L -2.857 114.244 C 51.867 140.273 113.097 154.839 177.728 154.839 C 308.236 154.839 424.873 95.447 502.084 2.218 Z"
        style={{ fill: color || 'rgb(255, 255, 255)' }}
      />
    </svg>
  );
};