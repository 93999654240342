import React from 'react';
import { Flex, Text, Spinner } from '@chakra-ui/react';

const Loading: React.FC<{}> = () => {
  return (
    <Flex
      flexDirection="column"
      h="100vh"
      w="100%"
      justifyContent="center"
      alignItems="center"
      bg="brown.50"
    >
      <Spinner thickness="4px" speed="0.65s" color="brown.200" size="xl" />
      <Text pt={4} fontWeight="semibold" color="brown.200">Loading...</Text>
    </Flex>
  );
};

export default Loading;
