export const SHORT_SLUGS = {
  'eating-habits': 'eh',
  'food-mindset': 'fm',
  environment: 'en',
  'cooking-style': 'cs',
  budget: 'bu',
  social: 'so',
} as const;

export const QUIZ_COLOURS = {
  'eating-habits': 'red',
  'food-mindset': 'brown',
  environment: 'cyan',
  'cooking-style': 'yellow',
  budget: 'pink',
  social: 'green',
};
