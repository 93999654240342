const themeColors = {
  darkGray: {
    '50': '#EFF1F5',
    '100': '#D3D9E4',
    '200': '#718096',
    '300': '#718096',
    '400': '#2D3748',
    '500': '#171923',
    '600': '#2D3748',
    '700': '#000000',
    '800': '#000000',
    '900': '#000000',
  },
  cyan: {
    '50': '#E7FDF9',
    '100': '#BDF9EF',
    '200': '#A0F7E8',
    '300': '#68F2DB',
    '400': '#3EEFD0',
    '500': '#14EBC6',
    '600': '#10BC9E',
    '700': '#0C8D77',
    '800': '#085E4F',
    '900': '#042F28',
  },
  red: {
    '50': '#FFEAE5',
    '100': '#FFC5B8',
    '200': '#FF7050',
    '300': '#FF7050',
    '400': '#FF7050',
    '500': '#FF7050',
    '600': '#FF7050',
    '700': '#991C00',
    '800': '#661300',
    '900': '#330900',
  },
  yellow: {
    '50': '#FFFDE5',
    '100': '#FFF8B8',
    '200': '#FFF271',
    '300': '#FFF271',
    '400': '#FFF271',
    '500': '#FFE800',
    '600': '#CCB900',
    '700': '#998B00',
    '800': '#665D00',
    '900': '#332E00',
  },
  green: {
    '50': '#ECF9F2',
    '100': '#C9EDDB',
    '200': '#45C181',
    '300': '#45C181',
    '400': '#45C181',
    '500': '#45C181',
    '600': '#45C181',
    '700': '#26734B',
    '800': '#194D32',
    '900': '#0D2619',
  },
  gray: {
    '50': '#EFF1F5',
    '100': '#D3D9E4',
    '200': '#B7C1D2',
    '300': '#9BA9C0',
    '400': '#7E90AF',
    '500': '#62789D',
    '600': '#4E607E',
    '700': '#3B485E',
    '800': '#000000',
    '900': '#000000',
  },
  lightGray: {
    '50': '#EFF1F5',
    '100': '#D3D9E4',
    '200': '#B7C1D2',
    '300': '#B7C1D2',
    '400': '#B7C1D2',
    '500': '#B7C1D2',
    '600': '#B7C1D2',
    '700': '#3B485E',
    '800': '#000000',
    '900': '#000000',
  },
  pink: {
    '50': '#FFEAE5',
    '100': '#FFDAD1',
    '200': '#FFC5B8',
    '300': '#FFBBB6',
    '400': '#FFBBB6',
    '500': '#FF9F8A',
    '600': '#FF675C',
    '700': '#991C00',
    '800': '#661300',
    '900': '#330900',
  },
  brown: {
    '50': '#F0EAE5',
    '100': '#F0EAE5',
    '200': '#CCB499',
    '300': '#CCB499',
    '400': '#B99874',
    '500': '#AA8255',
    '600': '#886844',
    '700': '#664E33',
    '800': '#443422',
    '900': '#221A11',
  },
};

export default themeColors;
