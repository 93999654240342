import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
// import base64url from 'base64url';
import Highlighter from 'react-highlight-words';
import { RWebShare } from 'react-web-share';
import dayjs from 'dayjs';
import Sticky from 'wil-react-sticky';
import copyToClipbard from 'copy-to-clipboard';

import {
  Heading,
  Container,
  Text,
  Button,
  Stack,
  Box,
  Flex,
  IconButton,
  Collapse,
  Image,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';

import {
  AddIcon,
  MinusIcon,
  CheckCircleIcon,
  QuestionOutlineIcon,
} from '@chakra-ui/icons';
import { Dictionary } from '@reduxjs/toolkit';

import { useDispatch, useSelector } from 'redux/hooks';
import { retrieveUser } from 'redux/slices/users';
import {
  ComputedResult,
  Quiz,
  Trait,
  Identity,
  Bucket,
  IdentityBucket,
  FullCategory,
} from 'types';
import { RootState } from 'redux/store';
import navRoutes from 'navigation/Routes';
import { analytics } from 'utils';

import { CurvedSectionBreak } from 'components/svg';
import { BucketList } from 'components/BucketList';
import Loading from 'components/Loading';

import foodIdLogo from 'assets/svg/food_id_logo.svg';
import foodIdLogoSmile from 'assets/svg/food_id_smile.svg';
import smileLogo from 'assets/svg/smile.svg';
import shareIcon from 'assets/svg/icons/content_share.svg';
import clipboardIcon from 'assets/svg/icons/content_copy.svg';

const {
  ScrollingProvider,
  Section,
  SectionLink,
} = require('react-scroll-section');

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

type PropsFromRedux = ConnectedProps<typeof connector>;

// Routing Props
interface MatchParams {
  id?: string;
}

interface OwnProps extends RouteComponentProps<MatchParams> {}

interface Props extends OwnProps, PropsFromRedux {}

const SectionTab: React.FC<{
  label: string;
  color: string;
  sectionId: string;
}> = ({ label, color, sectionId }) => (
  <SectionLink section={sectionId}>
    {({
      onClick,
      isSelected,
    }: {
      onClick: () => void;
      isSelected: boolean;
    }) => (
      <Box
        flex={1}
        p={3}
        bg={isSelected ? `${color}.200` : 'brown.50'}
        _hover={{ bg: isSelected ? `${color}.100` : 'gray.50' }}
        transition="all 0.3s"
        cursor="pointer"
        borderRadius="full"
        my={2}
        onClick={onClick}
      >
        <Text letterSpacing={1} fontWeight="medium" fontSize={['xs', 'md']}>
          {label}
        </Text>
      </Box>
    )}
  </SectionLink>
);

const Result: React.FC<{
  result: ComputedResult;
  isExpanded: boolean;
  setIsExpanded: (e: boolean) => void;
  quiz?: Quiz;
  trait?: Trait;
  allBuckets: Bucket[];
}> = ({ result, isExpanded, setIsExpanded, quiz, trait, allBuckets }) => {
  if (!quiz) return null;
  return (
    <>
      <Box
        bg={`${quiz.color}.200`}
        borderRadius="2xl"
        overflow="hidden"
        textAlign="left"
        w="100%"
        // zIndex={0}
      >
        <Flex
          alignItems="center"
          cursor="pointer"
          onClick={() => setIsExpanded(!isExpanded)}
          p={6}
        >
          <Flex flex={1}>
            <Heading fontWeight="normal" size="sm" pr={1}>
              {isExpanded ? quiz.name : `${quiz.name}:`}
            </Heading>
            <Heading size="sm" flex={1}>
              {isExpanded ? null : trait?.name}
            </Heading>
          </Flex>
          <IconButton
            aria-label={isExpanded ? 'Hide' : 'Expand'}
            icon={isExpanded ? <MinusIcon /> : <AddIcon />}
            isRound
            size="xs"
            variant="outline"
            onClick={() => setIsExpanded(!isExpanded)}
          />
        </Flex>
        <Collapse in={isExpanded} animateOpacity>
          <Box p={6} pt={0}>
            <Heading mb={6}>{trait?.name}</Heading>
            <Flex ml={-2} mb={3}>
              <BucketList results={[result]} />
            </Flex>
            <Text>{trait?.fullDescription}</Text>
          </Box>
        </Collapse>
      </Box>
    </>
  );
};

const Tab: React.FC<{
  isActive: boolean;
  color: string;
  isComplete: boolean;
  onClick: () => void;
}> = ({ isActive, color, isComplete, onClick }) => (
  <Flex
    bg={isActive ? `${color}.200` : `${color}.200`}
    _hover={!isActive ? { bg: `${color}.100` } : {}}
    flex={1}
    justifyContent="center"
    alignItems="center"
    borderTopRadius={10}
    mx={1}
    cursor="pointer"
    onClick={onClick}
    pb={2}
  >
    {isComplete ? (
      <CheckCircleIcon boxSize={6} />
    ) : (
      <QuestionOutlineIcon boxSize={6} />
    )}
  </Flex>
);

const DinnerPartySection: React.FC<{
  identity: Identity;
  name: string;
}> = ({ identity, name }) => {
  const [activeTab, setActiveTab] = useState<number>(identity.category.id);

  // Create a mapping matching each category ID to a list of guest names
  const [guestsNamesMapping, setGuestsNamesMapping] = useState<
    Record<number, string[]>
  >({});

  const activeTabGuests = guestsNamesMapping[activeTab];

  const categories = useSelector((state) => state.categories.entities);
  const categoriesList = Object.values(categories) as FullCategory[];
  const selectedCategory = categories[activeTab] as FullCategory;

  useEffect(() => {
    setActiveTab(identity.category.id);
  }, [identity.category.id]);

  /**
   * Retrieve the mapping of categories->friends from local storage
   * then update the state accordingly
   * (converting a dict of string->string to a dict of number->string[])
   * e.g: { "1": "A", "2": "B,C"}
   *  => { 1: ["A"], 2: ["B","C"] }
   */
  useEffect(() => {
    const catFriendMapStr =
      localStorage.getItem('categoryFriendMapping') || '{}';

    const catFriendMap = JSON.parse(catFriendMapStr) as Record<string, string>;

    const localStorageGuests = Object.keys(catFriendMap).reduce(
      (acc, k) => ({ ...acc, [parseInt(k)]: catFriendMap[k].split(',') }),
      {} as Record<number, string[]>
    );

    setGuestsNamesMapping(localStorageGuests);
  }, []);

  /**
   * Reverse of the above effect - when the state changes, save it into the localStorage
   * after converting the dict number->string[] to string->string
   * e.g: { 1: ["A"], 2: ["B","C"] }
   *  =>  { "1": "A", "2": "B,C"}
   */
  useEffect(() => {
    const catFriendMap = Object.keys(guestsNamesMapping).reduce((acc, k) => {
      const guestNames = guestsNamesMapping[parseInt(k)]
        .filter((a) => Boolean(a))
        .join(',');

      return Boolean(guestNames)
        ? {
            ...acc,
            [k]: guestsNamesMapping[parseInt(k)]
              .filter((a) => Boolean(a))
              .join(','),
          }
        : acc;
    }, {} as Record<string, string>);

    const catFriendMapStr = JSON.stringify(catFriendMap);

    localStorage.setItem('categoryFriendMapping', catFriendMapStr);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(guestsNamesMapping)]);

  return (
    <>
      <Flex flexDir={['column', 'column', 'row']}>
        <Heading mb={6} mr="16">
          {"What's my "}
          <Text as="span" variant="script" fontSize="5xl">
            {'Ultimate '}
          </Text>
          {' dinner party?'}
        </Heading>
        <Flex flexDir="column">
          <Text mb={4}>
            Imagining and planning your ‘ultimate’ dinner party will help you to
            understand the different cooking personality groups and how your
            friends and family come together around food in a social setting… as
            well as where you fit into the puzzle.
          </Text>
          <Text mb={14}>
            Without even realising, you can see how a mix of different
            personality types can compliment and enhance occasions. So, whether
            your cooking approach is independent or outgoing, we recommend
            taking the time to regularly host or attend dinner parties as part
            of your cooking and well-being journey. Pay attention to your guest
            list, it can really make a difference!
          </Text>
        </Flex>
      </Flex>
      <Box>
        <Flex height={14} mx={-1} mb={-2}>
          {categoriesList.map(({ id, color, name }) => (
            <Tab
              isActive={id === selectedCategory.id}
              color={color}
              isComplete={Boolean(
                guestsNamesMapping[id]?.filter((a) => Boolean(a)).length
              )}
              onClick={() => setActiveTab(id)}
            />
          ))}
        </Flex>
        <Box
          p={2}
          borderRadius={10}
          bg={`${selectedCategory.color}.200`}
          position="relative"
          mb={8}
        >
          <Box bg={`${selectedCategory.color}.200`} p={6} borderRadius={16}>
            <Flex alignItems="center" mb={6}>
              <Heading
                flex={1}
                size="lg"
              >{`${selectedCategory.name}s`}</Heading>
              <RWebShare
                sites={[
                  'mail',
                  'facebook',
                  'twitter',
                  'whatsapp',
                  'reddit',
                  'linkedin',
                  'telegram',
                ]}
                data={{
                  text: 'This is my Food iD',
                  title: 'Share your Food iD:',
                  url: `${window.location.href}?shared=true`,
                }}
              >
                <Flex cursor="pointer">
                  <Image src={shareIcon} w="30" h="30" />
                  <Text ml="4" fontSize="xl" whiteSpace="nowrap">
                    Share my Food iD
                  </Text>
                </Flex>
              </RWebShare>
            </Flex>

            <Text mb={8}>{selectedCategory.blurb_1}</Text>
            {activeTabGuests?.map((guest, index) => (
              <Flex alignItems="center" justifyContent="center" mb={4}>
                <Input
                  value={guest}
                  onChange={(e) => {
                    const newGuests = { ...guestsNamesMapping };
                    newGuests[activeTab][index] = e.target.value;
                    setGuestsNamesMapping(newGuests);
                  }}
                  placeholder="Name"
                  border="none"
                  textAlign="center"
                  _placeholder={{
                    color: `darkGray.900`,
                    fontWeight: 'normal',
                  }}
                  focusBorderColor="darkGray.900"
                  fontWeight="bold"
                  fontSize="lg"
                />
              </Flex>
            ))}
            <Stack
              direction="row"
              spacing={3}
              alignItems="center"
              justifyContent="center"
              position="relative"
            >
              {activeTabGuests?.length && (
                <IconButton
                  aria-label="Remove"
                  icon={<MinusIcon />}
                  onClick={() => {
                    const newGuests = { ...guestsNamesMapping };
                    newGuests[activeTab].pop();
                    setGuestsNamesMapping(newGuests);
                  }}
                  variant="outline"
                />
              )}
              <Button
                onClick={() => {
                  const newGuests = { ...guestsNamesMapping };
                  newGuests[activeTab] = newGuests[activeTab]
                    ? [...newGuests[activeTab], '']
                    : [''];
                  setGuestsNamesMapping(newGuests);
                }}
                variant="outline"
              >
                {`Add ${
                  ['a', 'e', 'i', 'o', 'u'].includes(
                    selectedCategory.name[0].toLocaleLowerCase()
                  )
                    ? 'an'
                    : 'a'
                } ${selectedCategory.name}`}
              </Button>
            </Stack>
            <Text my={8}>{selectedCategory.blurb_2}</Text>
          </Box>
        </Box>
      </Box>
    </>
  );
};

/**
 * Takes the given ComputedResults and IdentityBucket and cross-references
 * both of them to make sure that all the results match all the given buckets
 * @returns boolean
 */
const checkMatchingBuckets = (
  identityResults: ComputedResult[],
  quizzes: Quiz[],
  traits: Dictionary<Trait>,
  buckets: IdentityBucket[]
) => {
  const nonMatchingBucket = identityResults.find(({ traitId, slug }) => {
    const quiz = quizzes.find((q) => q.slug === slug);
    const trait = traitId && traits[traitId];

    if (!trait || !quiz) return true;

    // Run through identity buckets to disqualify identities
    // that don't match the user's trait results

    return buckets.find((idBucket) => {
      const isThisQuiz = idBucket.quiz === quiz.id;
      const containsThisCategory = trait.bucketCombination.includes(
        idBucket.category
      );
      return isThisQuiz && !containsThisCategory;
    });
  });

  return !nonMatchingBucket;
};

/**
 * Finds the relevant Identity/Approach based on the given ComputedResult array
 * @returns Identity | Approach | undefined
 */
function findMatchingId<T extends { buckets: IdentityBucket[] }>(
  identityResults: ComputedResult[] | undefined,
  identities: Dictionary<T>,
  quizzes: Quiz[],
  traits: Dictionary<Trait>
): T | undefined {
  return (
    identityResults &&
    Object.values(identities).find((identity) => {
      if (!identity) return false;

      const { buckets } = identity;

      if (!buckets.length) return false;

      // Check that all buckets for this identity match the given results
      return checkMatchingBuckets(identityResults, quizzes, traits, buckets);
    })
  );
}

type RoleSectionProps = {
  identity: Identity;
  title?: string;
  extraPadding?: boolean;
};

const RoleSection: React.FC<RoleSectionProps> = ({
  identity,
  title = 'Role',
  extraPadding,
}) => {
  const identities = useSelector(
    (state) =>
      Object.values(state.identities.entities).filter(
        (id) => id?.category.id === identity.category.id
      ) as Identity[]
  );

  return (
    <Section id="role">
      <Box bg={`${identity.category.color}.300`} pb={extraPadding ? '16' : '0'}>
        <Container maxW="3xl" textAlign="left" py={24}>
          <Heading variant="script" mb={6}>
            {title}
          </Heading>
          <Heading mb={8} size="2xl">
            {identity.category.name}
          </Heading>
          <Text mb={8}>
            <Highlighter
              searchWords={['Dreamers', 'Inventors', 'Coordinators', 'Tasters']}
              textToHighlight={identity.category.description}
              highlightTag={({ children }) => (
                <span
                  style={{
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                >
                  {children}
                </span>
              )}
            />
          </Text>
          <Heading>The {identity.category.name}s</Heading>
          <Flex>
            <Text>
              {identities.reduce(
                (acc, id, idx) =>
                  `${acc}The ${id.name}${
                    idx + 1 < identities.length ? ', ' : ''
                  }`,
                ''
              )}
            </Text>
          </Flex>
          <Flex flexFlow="row wrap" px="2">
            {identities.map((id) => (
              <Box w={['50%', '25%']} p="4">
                <Box
                  backgroundImage={id.imageUrl}
                  height={['100px', '200px']}
                  backgroundSize="contain"
                  backgroundRepeat="no-repeat"
                  backgroundPosition="bottom"
                />
              </Box>
            ))}
          </Flex>
        </Container>
      </Box>
    </Section>
  );
};

const MyFoodId: React.FC<Props> = ({
  history,
  quizzes,
  traits,
  identities,
  approaches,
  buckets,
  match: { params },
}) => {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [userResults, setUserResults] = useState<ComputedResult[] | null>(null);
  const [expandedTest, setExpandedTest] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const user = useSelector((state) => state.user);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const isSharedFoodId = Boolean(urlParams.get('shared'));

  const quizzesArr = Object.values(quizzes) as Quiz[];
  const { id } = params;

  const toast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return;
      await dispatch(retrieveUser(id));
    };

    fetchData().then(async () => {
      await sleep(500);
      setIsLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!user) return;

    setUserResults(user.results);
    setName(user.name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(user)]);

  useEffect(() => {
    if (isSharedFoodId || localStorage.getItem('challengesModalDismissed')) {
      return;
    }

    setTimeout(() => {
      setShowModal(true);
      localStorage.setItem('challengesModalDismissed', 'true');
    }, 10000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const identityResults = userResults?.filter(
    ({ slug }) =>
      slug === 'eating-habits' ||
      slug === 'food-mindset' ||
      slug === 'environment' ||
      slug === 'cooking-style'
  );

  const approachResults = userResults?.filter(
    ({ slug }) => slug === 'budget' || slug === 'social'
  );

  const identity = findMatchingId(
    identityResults,
    identities,
    quizzesArr,
    traits
  );

  const approach = findMatchingId(
    approachResults,
    approaches,
    quizzesArr,
    traits
  );

  useEffect(() => {
    if (!identity) return;
    analytics.setUserProperties({ foodId: identity.name });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(identity)]);

  if (isLoading) return <Loading />;

  if (!user || !identity || !approach || !userResults) {
    // TODO : fix this - needs design input
    return (
      <Flex>
        <Text>ERROR MESSAGE GOES HERE</Text>
      </Flex>
    );
  }

  const handleCopyToClipboard = () => {
    copyToClipbard(`${window.location.href}?shared=true`);
    toast({
      description: 'Link copied to clipboard',
      status: 'success',
      duration: 1000,
      position: 'top',
    });
  };

  return (
    <ScrollingProvider>
      <Box bg="white">
        {/* Header section */}
        <Box position="relative" bg={`${identity.category?.color}.200`}>
          <Box
            position="absolute"
            bottom={0}
            left={0}
            right={0}
            height="40vw"
            maxHeight="50%"
            overflow="hidden"
          >
            <CurvedSectionBreak />
          </Box>
          <Container maxW="3xl" textAlign="center" py={12}>
            <Flex pb={8} justifyContent={['center', 'center', 'flex-start']}>
              <Text fontWeight="medium" fontSize="xl">
                We'd like to introduce you to
              </Text>
            </Flex>
            <Flex
              flexDir="column"
              backgroundColor="brown.100"
              p={['6', '10']}
              borderRadius="lg"
              boxShadow="md"
              position="relative"
            >
              <Flex alignItems="center">
                <Text fontSize={['3xl', '5xl']} fontWeight="extrabold">
                  {name}'s
                </Text>
                <Image
                  src={foodIdLogo}
                  maxW={['120px', '200px']}
                  ml={['4', '8']}
                />
              </Flex>
              {isSharedFoodId ? (
                <Flex mt="6" flexDir="column">
                  <Text textAlign="left">
                    Take a look around and get to know {name} that little bit
                    better and help them achieve their foodie goals!
                  </Text>
                  <Text textAlign="left" fontWeight="bold" mt="6">
                    Take the Food iD test here and pair yourself with the food
                    you cook and eat.
                  </Text>
                  <Flex justifyContent="flex-start">
                    <Button
                      colorScheme="brand"
                      mt="8"
                      onClick={() =>
                        history.push(navRoutes.onboarding.home.path())
                      }
                      size="lg"
                    >
                      Take the test
                    </Button>
                  </Flex>
                </Flex>
              ) : (
                <Flex mt="6">
                  <Flex
                    cursor="pointer"
                    mr={['4', '10']}
                    onClick={handleCopyToClipboard}
                  >
                    <Image src={clipboardIcon} w="30" h="30" />
                    <Text ml="4" fontSize="xl">
                      Copy link
                    </Text>
                  </Flex>
                  <RWebShare
                    sites={[
                      'mail',
                      'facebook',
                      'twitter',
                      'whatsapp',
                      'reddit',
                      'linkedin',
                      'telegram',
                    ]}
                    data={{
                      text: 'This is my Food iD',
                      title: 'Share your Food iD:',
                      url: `${window.location.href}?shared=true`,
                    }}
                  >
                    <Flex cursor="pointer">
                      <Image src={shareIcon} w="30" h="30" />
                      <Text ml="4" fontSize="xl" whiteSpace="nowrap">
                        Share my Food iD
                      </Text>
                    </Flex>
                  </RWebShare>
                </Flex>
              )}
              <Image
                position="absolute"
                src={identity.imageUrl}
                alt={identity.name}
                w="35%"
                minW="120px"
                maxWidth="500px"
                h="300px"
                right={0}
                bottom="-250px"
              />
            </Flex>
            <Flex alignItems="flex-start" flexDir="column" p="6">
              <Heading variant="script" fontSize={['5xl', '7xl', '8xl']} pb={4}>
                {identity.character}
              </Heading>
              <Heading
                size="lg"
                pb={12}
                maxW={['59%', '100%']}
                textAlign="left"
              >
                {`The ${identity.name}`}
              </Heading>
            </Flex>
          </Container>
        </Box>
        {/* Section visible to friends viewing someone else's FoodID */}
        {isSharedFoodId && (
          <>
            <Section id="style">
              <Container maxW="3xl" textAlign="left" pt={[4, 12]} pb={24}>
                <Heading variant="script" mb={8}>
                  Style & Approach
                </Heading>
                <Text mb={14}>
                  {identity.descriptionTemplate?.replaceAll(
                    '{{user_name}}',
                    name
                  )}
                </Text>
                <Flex flexWrap="wrap" justifyContent="center" mx={-2} mb={8}>
                  <BucketList showPopupsOnly results={userResults} />
                </Flex>
              </Container>
            </Section>
            <RoleSection identity={identity} extraPadding />
          </>
        )}
        {/* Section visible to users after they completed all the quizzes */}
        {!isSharedFoodId && (
          <>
            <Sticky>
              <Box bg="white">
                <Container maxW="3xl" textAlign="center">
                  <Flex flexDir={['column', 'column', 'row']}>
                    <SectionTab
                      label="MY STYLE"
                      sectionId="style"
                      color={identity.category.color}
                    />
                    <Box w={[2, 3, 4]} />
                    <SectionTab
                      label="MY APPROACH"
                      sectionId="approach"
                      color={identity.category.color}
                    />
                    <Box w={[2, 3, 4]} />
                    <SectionTab
                      label="MY ROLE"
                      sectionId="role"
                      color={identity.category.color}
                    />
                    <Box w={[2, 3, 4]} />
                    <SectionTab
                      label="MY HABITS"
                      sectionId="habits"
                      color={identity.category.color}
                    />
                  </Flex>
                </Container>
              </Box>
            </Sticky>
            <Section id="style">
              <Container maxW="3xl" textAlign="left" pt={[4, 12]} pb={24}>
                <Heading variant="script" mb={8}>
                  My Style
                </Heading>
                <Text mb={14}>{identity.description}</Text>
                <Stack direction="column" spacing={3} w="100%">
                  {identityResults?.map((result) => (
                    <Result
                      result={result}
                      quiz={quizzes[result.slug]}
                      trait={traits[result.traitId as string]}
                      allBuckets={Object.values(buckets) as Bucket[]}
                      isExpanded={expandedTest === result.slug}
                      setIsExpanded={(e) => {
                        if (!e) {
                          setExpandedTest(null);
                        } else {
                          setExpandedTest(result.slug);
                        }
                      }}
                    />
                  ))}
                </Stack>
              </Container>
            </Section>
            <Section id="approach">
              <Container maxW="3xl" textAlign="left" py={24} pb={24}>
                <Heading variant="script" mb={6}>
                  My Approach
                </Heading>
                <Heading mb={8}>{approach.name}</Heading>
                <Text mb={14}>{approach.description}</Text>
                <Stack direction="column" spacing={3}>
                  {approachResults?.map((result) => (
                    <Result
                      result={result}
                      quiz={quizzes[result.slug]}
                      trait={traits[result.traitId as string]}
                      allBuckets={Object.values(buckets) as Bucket[]}
                      isExpanded={expandedTest === result.slug}
                      setIsExpanded={(e) => {
                        if (!e) {
                          setExpandedTest(null);
                        } else {
                          setExpandedTest(result.slug);
                        }
                      }}
                    />
                  ))}
                </Stack>
              </Container>
            </Section>
            <RoleSection identity={identity} title="My Role" />
            <Section id="dinner_party">
              <Container maxW="3xl" textAlign="left" py={24}>
                <DinnerPartySection identity={identity} name={name} />
              </Container>
            </Section>
            <Section id="habits">
              <Box>
                <Container maxW="3xl" textAlign="left" py={24}>
                  <Heading mb={6}>
                    {"Let's "}
                    <Text as="span" variant="script" fontSize="5xl">
                      {'unlock '}
                    </Text>
                    {' some new habits'}
                  </Heading>
                  <Text mb={8}>
                    By understanding your natural qualities and tendencies and
                    understanding where the optimum balance lies in certain
                    fields, you'll be able to tailor your own journey of
                    progression to get more joy out of food.
                  </Text>
                  <Text fontWeight="bold" mb={4}>
                    My Qualities
                  </Text>
                  <Flex flexWrap="wrap" justifyContent="center" mx={-2} mb={8}>
                    <BucketList showPopupsOnly results={userResults} />
                  </Flex>

                  <Text mb={8}>
                    On the 1st of every month, we'll recommend you 3-6 things to
                    do which naturally align with each of your qualities,
                    letting you build new and progressive food habits with ease.
                    With over 6,000 possible combinations of qualities, your
                    monthly task list will be highly personalised and tailored
                    just for you and your needs. Are you ready for your journey
                    to begin? We're with you every step of the way...
                  </Text>
                  <Button
                    colorScheme="brand"
                    mb={8}
                    onClick={() =>
                      history.push(navRoutes.public.challenges.path(id))
                    }
                    size="lg"
                  >
                    {`View My ${dayjs().format('MMMM')} Challenges`}
                  </Button>
                </Container>
              </Box>
            </Section>
          </>
        )}
      </Box>
      {/* Bottom banner for shared foodID screen */}
      {isSharedFoodId && (
        <Box
          w="100vw"
          position="fixed"
          bottom="0"
          zIndex={10}
          backgroundColor="brown.300"
        >
          <Container maxW="3xl" textAlign="center" py="6">
            <Flex flexDir={['column', 'row']} alignItems="center">
              <Flex flex={1} flexDir="row" alignItems="center" mb={['4', '0']}>
                <Text textAlign="left" fontWeight="bold" mr="4">
                  Discover your
                </Text>
                <Image src={foodIdLogoSmile} maxW="100px" />
              </Flex>
              <Button
                colorScheme="brand"
                onClick={() => history.push(navRoutes.onboarding.home.path())}
                size="lg"
              >
                Take the test
              </Button>
            </Flex>
          </Container>
        </Box>
      )}
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <ModalOverlay />
        <ModalContent w={['200px', '800px']} mt="28">
          <ModalHeader>
            <Flex
              alignItems="center"
              justifyContent="center"
              left="0"
              overflow="visible"
              position="absolute"
              top="-75px"
              w="100%"
            >
              <Image src={smileLogo} w={['120px', '150px']} />
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pt="16">
            <Heading textAlign="center" fontSize={['xl', '2xl']}>
              Your challenges are ready!
            </Heading>
            <Text textAlign="center" mt="2">
              Check out your first round of challenges, designed to compliment
              your new found foodie habits!
            </Text>
            <Flex
              mt="6"
              pb="6"
              w="100%"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                colorScheme="brand"
                onClick={() =>
                  history.push(navRoutes.public.challenges.path(id))
                }
                size="md"
              >
                {`View My ${dayjs().format('MMMM')} Challenges`}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ScrollingProvider>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    quizzes: state.quizzes.entities,
    traits: state.traits.entities,
    identities: state.identities.entities,
    approaches: state.approaches.entities,
    buckets: state.buckets.entities,
  };
};

const connector = connect(mapStateToProps);

export default connector(MyFoodId);
