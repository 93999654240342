import React from 'react';
import { Provider } from 'react-redux';

import { ChakraProvider } from '@chakra-ui/react';

import { store } from 'redux/store';

import AppRouter from 'navigation/AppRouter';
import history from 'navigation/History';

import theme from 'theme';

const App = () => {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <AppRouter history={history} />
      </ChakraProvider>
    </Provider>
  );
};

export default App;
