import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { urls } from 'constants/api';
import { ComputedResult, User } from 'types';
import { http } from 'utils';

type RegisterData = {
  name: string;
  email: string;
  password: string;
  results: ComputedResult[];
  friends: { name: string; email: string }[];
};

type RegisterResponseData = { user: User; jwt: string };

export const register = createAsyncThunk(
  'auth/register',
  async (data: RegisterData) =>
    await http.post<any, AxiosResponse<RegisterResponseData>>(urls.register, {
      ...data,
      username: data.email,
    })
);
